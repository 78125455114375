<template>
  <section class="bg-gray-50 dark:bg-gray-900 py-3 sm:py-5 w-full">
    <div class="px-2 mx-auto   lg:px-4">
      <div class="  bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
        <div
          class="flex flex-col px-4 py-3   lg:flex-row  items-center lg:justify-between lg:space-y-0 lg:space-x-4  bg-gray-100">
          <div class="pr-3   ">
            <a class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              @click="onCancel">
              <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd" />
              </svg>
            </a>
          </div>


          <div class="flex items-center flex-1 space-x-4   ">
            <div class="flex items-center divide-x divide-gray-300 dark:divide-gray-700   ">
              <div class="flex flex-col pl-0 space-x-2 sm:px-2  ">
                <h2
                  class="pr-6  pl-2 text-left   text-3xl tracking-tight font-bold text-gray-900 dark:text-white inline-flex items-center">
                  <ContentInlineEditor :input="title" :is-developer="isDeveloper" @change="onChangeTitle" />

                </h2>

                <h4 class="  px-8 text-left  text-xl tracking-tight font-semibold text-gray-500">
                  <ContentInlineEditor :input="subtitle" :is-developer="isDeveloper" @change="onChangeSubTitle" />

                </h4>
              </div>
            </div>
          </div>

          <div
            class="flex flex-col flex-shrink-0  bg-gray-100  md:flex-row md:items-center lg:justify-end  space-y-0 md:space-x-3">
            <button
              v-if="(template && template.options && (template?.options?.displayGenerate == true)) && (id != 'new-item')"
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onGenerateSheet('eu-fr')">
              {{ common_labels['BUTTON_GENERATE_SHEET'][lg] }}
            </button>
            <button v-if="(template && template.options && template.options.displayArchive) && (id != 'new-item')"
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
              @click="onArchiveSheet">
              {{ common_labels['BUTTON_ARCHIVED'][lg] }}
            </button>


            <button type="button"
              class="hidden flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
              @click="onGeneratePDFReport">
              ***********
            </button>

            <button v-if="(template && template.options && template.options.displayDelete) && (id != 'new-item')"
              type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
              @click="onDeleteSheet">
              {{ common_labels['BUTTON_DELETE'][lg] }}
            </button>
            <button v-if="(template && template.options && (template.options.displaySave==true))" type="button"
              class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              @click="onSaveSheet">
              {{ common_labels['BUTTON_SAVE'][lg] }}
            </button>

            <button type="button"
              class="hidden flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
              <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="none"
                viewbox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>
              Update stocks 1/250
            </button>
            <button type="button"
              class="hidden flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
              <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24"
                stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
              </svg>
              common_labels
            </button>
          </div>
        </div>

        <div class="min-h-500 flex flex-col h-screen" style="min-height:400px">
          <div class="mb-4 border-b border-gray-200 dark:border-gray-700 flex-none"
            :class="{hidden : !(template != undefined && template.options != undefined && (template.options.displayTab != false))}">
            <ul v-if="template != undefined" :id="`tabs-form-${id}`"
              class="flex flex-wrap -mb-px text-sm font-medium text-center"
              :data-tabs-toggl2="`#myFormTabContent-${id}`" role="tablist">

              <li v-for="(tab,index) in template?.children" :key="tab.id" class="mr-2 relative " role="presentation">
                <button :id="`tab-form-${tab.id}`" class=" inline-block p-4 border-b-2 rounded-t-lg" type="button"
                  role="tab" :aria-controls="`tab-form-${tab.id}`" aria-selected="false" @click="onChangeTab(index)">
                  {{ tab.title[lg] }}
                </button>
                <button v-if="isDeveloper == true" :id="`tab-${tab.id}-dropdown-button`"
                  :data-dropdown-toggle="`tab-${tab.id}-dropdown`"
                  class="  z-10 absolute -top-3 right-1   flex cursor-pointer text-gray-500 bg-orange-300  inline-flex items-center text-sm font-medium hover:bg-orange-100   p-1.5   text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100"
                  type="button">
                  <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                  </svg>
                </button>
                <div :id="`tab-${tab.id}-dropdown`"
                  class="hidden z-40 w-56  bg-gray-50 rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600">
                  <ul class="py-1 text-sm" :aria-labelledby="`tab-${tab.id}-dropdown-button`">
                    <li>
                      <button type="button"
                        class="flex w-full items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-gray-700 dark:text-gray-200"
                        @click="manageField(item)">
                        <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20"
                          fill="currentColor" aria-hidden="true">
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                        </svg>
                        Modifier
                      </button>
                    </li>
                    <li>
                      <button type="button"
                        class="flex w-full items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-gray-700 dark:text-gray-200">
                        <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20"
                          fill="currentColor" aria-hidden="true">
                          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                        Ajouter un onglet
                        <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                          viewBox="0 0 6 10">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 9 4-4-4-4" />
                        </svg>
                      </button>
                    </li>
                    <li>
                      <button type="button"
                        class="flex w-full items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 text-red-500 dark:hover:text-red-400"
                        @click="deleteField(item)">
                        <svg class="w-4 h-4 mr-2" viewbox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true">
                          <path fill-rule="evenodd" clip-rule="evenodd" fill="currentColor"
                            d="M6.09922 0.300781C5.93212 0.30087 5.76835 0.347476 5.62625 0.435378C5.48414 0.523281 5.36931 0.649009 5.29462 0.798481L4.64302 2.10078H1.59922C1.36052 2.10078 1.13161 2.1956 0.962823 2.36439C0.79404 2.53317 0.699219 2.76209 0.699219 3.00078C0.699219 3.23948 0.79404 3.46839 0.962823 3.63718C1.13161 3.80596 1.36052 3.90078 1.59922 3.90078V12.9008C1.59922 13.3782 1.78886 13.836 2.12643 14.1736C2.46399 14.5111 2.92183 14.7008 3.39922 14.7008H10.5992C11.0766 14.7008 11.5344 14.5111 11.872 14.1736C12.2096 13.836 12.3992 13.3782 12.3992 12.9008V3.90078C12.6379 3.90078 12.8668 3.80596 13.0356 3.63718C13.2044 3.46839 13.2992 3.23948 13.2992 3.00078C13.2992 2.76209 13.2044 2.53317 13.0356 2.36439C12.8668 2.1956 12.6379 2.10078 12.3992 2.10078H9.35542L8.70382 0.798481C8.62913 0.649009 8.5143 0.523281 8.37219 0.435378C8.23009 0.347476 8.06631 0.30087 7.89922 0.300781H6.09922ZM4.29922 5.70078C4.29922 5.46209 4.39404 5.23317 4.56282 5.06439C4.73161 4.8956 4.96052 4.80078 5.19922 4.80078C5.43791 4.80078 5.66683 4.8956 5.83561 5.06439C6.0044 5.23317 6.09922 5.46209 6.09922 5.70078V11.1008C6.09922 11.3395 6.0044 11.5684 5.83561 11.7372C5.66683 11.906 5.43791 12.0008 5.19922 12.0008C4.96052 12.0008 4.73161 11.906 4.56282 11.7372C4.39404 11.5684 4.29922 11.3395 4.29922 11.1008V5.70078ZM8.79922 4.80078C8.56052 4.80078 8.33161 4.8956 8.16282 5.06439C7.99404 5.23317 7.89922 5.46209 7.89922 5.70078V11.1008C7.89922 11.3395 7.99404 11.5684 8.16282 11.7372C8.33161 11.906 8.56052 12.0008 8.79922 12.0008C9.03791 12.0008 9.26683 11.906 9.43561 11.7372C9.6044 11.5684 9.69922 11.3395 9.69922 11.1008V5.70078C9.69922 5.46209 9.6044 5.23317 9.43561 5.06439C9.26683 4.8956 9.03791 4.80078 8.79922 4.80078Z" />
                        </svg>
                        Supprimer
                      </button>
                    </li>
                  </ul>
                </div>
                <span class="hidden">:data-tabs-target="`#tab-content-${tab.id}`" {{ index }}</span>
              </li>
            </ul>
          </div>
          <div class="w-full px-6 mb-2 mt-6 hidden">
            <ol class="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0  ">


              <li v-for="(tab,index) in template?.children" :key="tab.id"
                class="flex items-center space-x-2.5 ml-3 text-left"
                :class="{'text-blue-600 dark:text-blue-500' : (currentTab == index), 'text-gray-500 dark:text-gray-400': (currentTab != index)}">
                <span class="mr-3 flex items-center justify-center w-8 h-8  rounded-full shrink-0 dark:border-blue-500"
                  :class="{ '  bg-blue-600 text-white' : (index == currentTab) , 'border border-blue-600' : (index < currentTab) , 'border border-gray-400' : (index > currentTab) }">
                  {{ index + 1 }}
                </span>
                <span>
                  <h3 class="font-medium leading-tight"
                    :class="{ 'text-blue-600 font-bold' : (index == currentTab) , 'text-blue-600' : (index < currentTab) , 'text-gray-400' : (index > currentTab) }">
                    {{ tab.title[lg] }}
                  </h3>
                  <p class="text-sm"
                    :class="{ 'text-blue-600 font-bold' : (index == currentTab) , 'text-blue-600' : (index < currentTab) , 'text-gray-400' : (index > currentTab) }">
                    Step details here</p>
                </span>
              </li>
            </ol>
          </div>

          <div v-if="template != undefined" :id="`#myFormTabContent-${id}`" class="">
            <div v-for="(tab,index) in template?.children" :id="`tab-form-content-${tab.id}`" :key="tab.id"
              :class="`hidden p-0 rounded-lg bg-gray-100 dark:bg-gray-800 flex flex-wrap  `"
              role="tabpanel" :aria-labelledby2="`tab-form-${tab.id}`">

              <B4FContent v-for="body in tab.body" :id="`sheet-${tab.id}`" :ref="`sheet-${tab.id}`" :key="body.id"
                :isActive="(currentTab == index)" :currentTab="currentTab" :indexTab="index" :countries="countries"
                :config="body" :record="record" :settings="tab" :custom-action="customAction"
                @manage-postal-address="managePostalAddress" @change-view="onChangeView"
                @manage-button-click="manageButtonClick" @select-image-modal-open="OnSelectImageModalOpen"
                @change-field-parameter="changeFieldParameter" @manage-field="manageField" @add-field="addField"
                @delete-field="deleteField" @select-image-modal-close="OnSelectImageModalClose"
                @value-changed="onValueChanged" @action="onAction" />



            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Main modal -->
    <div id="delete-confirm-modal" tabindex="-1" aria-hidden="true"
      class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full">
      <div class="relative p-4 w-full max-w-md h-full md:h-auto">
        <!-- Modal content -->
        <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <button type="button"
            class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="onDeleteCancel">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
          <svg class="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
              clip-rule="evenodd" />
          </svg>
          <p class="mb-4 text-gray-500 dark:text-gray-300">
            {{ message_confirmed }}
          </p>
          <div class="flex justify-center items-center space-x-4">
            <button type="button"
              class="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              @click="onDeleteCancel">
              Non
            </button>
            <button
              class="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
              @click="onDeleteConfirmed">
              Oui
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

  import { Tabs } from "flowbite";

  import common_labels from "@/assets/lg/common.json"
  import B4FContent from '@/components/ContentComponent.vue'
  import ContentInlineEditor from '@/components/content/ContentInlineEditor.vue'
  import { mapGetters } from "vuex";
  import { Modal } from 'flowbite';

  export default {
    name: 'FormComponent',
    components: {
      B4FContent, ContentInlineEditor
    },
    emits: ['action'],
    props: {
      config: Object,
      record: Object,
      customAction: Object,
      template: Object,
      countries: Array,
      title: String,
      subtitle: String
    },
    data() {
      return {
        id: null,
        currentTab: 0,
        deleteConfirmModal: null,
        common_labels: common_labels,
        lg: "FR",
        tabs: null,
        tabElements: [],
        message_confirmed: "???",
        timeout: null,

      }
    },

    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
      body_height: function () {
        return "580px"
      },
      isDeveloper: function () {

        return this.user?.isDeveloper;
      },
    },
    watch: {

      record: function () {
        //  console.log("FORMCOMPONENT ----------------- WATCH RECORD")
        this.id = this.record?._id;
      },

      template: async function () {
        //   console.log("FORMCOMPONENT ----------------- WATCH TEMPLATE")
        this.index = 0;
        //   console.log(this.template)


        this.$nextTick(() => {

          this.timeout = setTimeout(this.loadTabs(), 9000);
        });

      }
    },
    mounted() {
      //  console.log("FORMCOMPONENT ----------------- BEGIN MOUNTED")
      let id = this.record?._id;
      if (id == undefined) id = "new-item";

      this.id = id

      if (this.template != undefined) {
        if (this.template?.children != undefined) {
          this.timeout = setTimeout(this.loadTabs(), 3000);
        }
      }

      const modalEl = document.getElementById('delete-confirm-modal');

      this.deleteConfirmModal = new Modal(modalEl, {
        placement: 'center'
      });
      //  console.log("FORMCOMPONENT ----------------- END MOUNTED")
    },
    methods: {
      async onGeneratePDFReport() {
        this.$emit("generatePdf")
      },

      onAction: function (event) {

        this.$emit("action", event)
      },


      onChangeTab: function (index) {

        if (this.tabs == undefined) {
          this.loadTabs()
        }
        this.currentTab = index;
        //  this.tabs.show(this.currentTab);
      },

      onChangeTitle: function () {
        console.log("onChangeTitle - todo")
      },

      onChangeSubTitle: function () {
        console.log("onChangeSubTitle - todo")
      },

      managePostalAddress: function (field) {


        this.$emit("managePostalAddress", field)

      },

      manageButtonClick: function (field) {


        this.$emit("manageButtonClick", field)

      },


      changeFieldParameter: function (parameters) {

        this.$emit("changeFieldParameter", parameters)
      },

      onValueChanged: function (event) {

        this.$emit("valueChanged", event)
      },
      loadTabs: function () {
        //    console.log("FORMCOMPONENT ----------------- BEGIN LOADTABS")

        clearTimeout(this.timeout);


        if (this.template == undefined || this.template?.children == undefined) {

          if (this.options == null) this.options = {}
          this.options.defaultTabId = null;
          this.tabs = null;
          return
        }
        let tabElements = []

        //  console.log("this.template.children ", this.template.children)
        for (let i = 0; i < this.template?.children.length; i++) {
          let child = this.template?.children[i];
          //   console.log(child)
          //   console.log('#tab-' + child.id)

          //     console.log('#tab-form-' + child.id, document.querySelector('#tab-form-' + child.id))
          //     console.log('#tab-form-content--' + child.id, document.querySelector('#tab-form-content-' + child.id))

          if (document.querySelector('#tab-form-' + child.id) != undefined)
            tabElements.push(
              {
                id: child.id,
                triggerEl: document.querySelector('#tab-form-' + child.id),
                targetEl: document.querySelector('#tab-form-content-' + child.id)
              }

            )
        }
        //    console.log("this.tabElements.length ", tabElements.length)
        //    console.log(tabElements)





        if (tabElements.length < 1) {
          //    console.log("FORMCOMPONENT --------------------- NO VIEW", this.template.children.length)
          if (this.options == null) this.options = {}
          this.options.defaultTabId = null;
          this.tabs = [];
          return
        }
        this.tabElements = tabElements
        //  console.log("tabElement -----------------------------------")
        //  console.log((tabElements))



        let options = {
          //   defaultTabId: 'home',
          activeClasses: 'text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500',
          inactiveClasses: 'text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
          //onShow: () => {
          //    
          //}
        }
        const instanceOptions = {
          id: 'tabs-form-' + this.id,
          override: true
        };

        const tabsElement = document.getElementById('tabs-form-' + this.id);
        // this.options.defaultTabId = this.tabElements[0].id;

        try {
          this.tabs = new Tabs(tabsElement, tabElements, options, instanceOptions);
          // open tab item based on id
          if (this.tabElements[0].id != undefined) {

            this.$nextTick(function () {
              //      console.log("FORMCOMPONENT ----------------- SHOW TABS")
              this.tabs.show(this.tabElements[0].id);
            });

          }
        }
        catch (e) {
          console.log(e)
          console.log("Erreur Tab")
        }
        //  console.log("this.currentTab = 0 ----------------- END LOADTABS")
        this.currentTab = 0

        //  console.log("FORMCOMPONENT ----------------- END LOADTABS")
      },

      manageField: function (config) {
        this.$emit("manageField", config)
      },
      addField: function (config) {
        this.$emit("addField", config)
      },

      deleteField: function (config) {
        this.$emit("deleteField", config)
      },

      OnSelectImageModalClose() {

      },

      onChangeView(item) {
        this.$emit("changeView", item)
      },

      onCancel() {
        this.$emit("cancel")
      },

      OnSelectImageModalOpen: function (item) {
        this.$emit('selectImageModalOpen', item)
      },
      async onDeleteConfirmed() {
        await this.$emit("delete", this.record);
        this.deleteConfirmModal.hide();
      },
      onDeleteCancel() {

        this.deleteConfirmModal.hide();
      },
      onDeleteSheet() {
        this.message_confirmed = "Etes vous sûr de supprimer cette élement ?"
        this.deleteConfirmModal.show();

      },
      onArchiveSheet() {
        this.message_confirmed = "Etes vous sûr d'archiver cette fiche ?\nElle ne sera plus accesible pour vos clients."

        this.deleteConfirmModal.show();

      },
      onViewSheet() {
        //  this.message_confirmed = "Etes vous sûr d'archiver cette fiche ?\nElle ne sera plus accesible pour vos clients."
        //  this.deleteConfirmModal.show();

        //this.onGenerateSheet(this.record.market + "-" + this.record.langage)

        this.$emit("display", { id: this.record.id })

      },
      onSaveSheet() {


        let record = {}
        let isError = true
        console.log("FormComponent - Contoldata")



        console.log("FormComponent - getData")

        for (let i = 0; i < this.template?.children?.length; i++) {
          let tab = this.template.children[i].id;

          for (let i = 0; i < this.$refs['sheet-' + tab].length; i++) {
            console.log('getData ', tab, i, this.$refs['sheet-' + tab][i].getData != undefined)
            let response = this.$refs['sheet-' + tab][i].getData();
            isError = isError && response.isError
            record = { ...record, ...response.data }
          }

        }

        console.log("SAVE SAVE SAVE SAVE SAVE SAVE ------------------------------------")
        console.log(record)


        // if (isError) alert("FormComponent not valid " + isError)
        // else
        this.$emit("save", record)
      },
      onGenerateSheet(market) {


        this.$emit("generate", { id: this.record.id, market: market })




      }
    },

  }
</script>