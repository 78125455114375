<template>
  <!-- <TopNav class="hidden"/>-->
 

  <div class="h-screen grid  sm:ml-12"   :class="{'grid grid-cols-12 gap-4  ' : chatOpened, 'w-full':!chatOpened}">

    <SideNav />


    <div class="h-screen flex flex-col  "  :class="{'  col-span-9' : chatOpened, 'col-span-12':!chatOpened}">

      <div class="flex-none  ">

        <div class=" w-full  bg-gray-800 flex-none" v-if="isMultiSite" >
          <div class="inline-flex rounded-md shadow-sm" role="group">
            <button type="button" class="px-4 pb-1 pt-2 text-xs font-medium     cursor-pointer  text-white border-b-4 "
              :class="{'   font-bold  border-green-300': (selected==0), 'border-gray-800    hover:border-orange-500 ' : (selected!=0)}"
              @click="selectSite(0,'all')">
              Tous les sites
            </button>
            <button v-for="(entity, index) in user?.tenant?.entities" :key="entity.id" type="button"
              class="px-4 pb-1 pt-2  text-xs font-medium  text-white  border-b-4 cursor-pointer"
              :class="{'   font-bold  border-green-300': (selected== (index+1)), ' border-gray-800  hover:border-orange-500 ' : (selected!= (index+1))}"
              @click="selectSite(index + 1, entity._id)">
              {{ entity.name }}
            </button>
          </div>
        </div>

      </div>
      <div class="flex-1 overflow-auto">
        <router-view class="w-full" :refresh="timestamp" />
      </div>


    </div>


    <div class="hidden h-screen col-span-3 ">
      <ChatComponent @closeChat="closeChat" />
    </div>
  </div>
  
  <!-- drawer init and toggle -->
</template>

<script>
  // import TopNav from '@/components/TopNav.vue'
  import SideNav from '@/components/SideNav.vue'
  import ChatComponent from '@/components/ChatComponent.vue'
  import { mapActions, mapGetters } from "vuex";
  export default {
    name: 'AdminLayout',
    data() {
      return {
        selected: 0,
        timestamp: null,
        chatOpened: false
      }
    },

    computed: {
      isMultiSite: function () {

        return (this.user?.tenant?.entities?.length > 0)

      },

      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
    },
    watch: {
      'user.entity_id': function () {
        this.timestamp = (new Date()).toISOString()
      }
    },
    mounted() {
      if (this.user?.entity_id == "66a4a19c8d43c894c147758a") this.selected = 1;
      if (this.user?.entity_id == "66a4a1cb8d43c894c147758b") this.selected = 2;

      this.timestamp = (new Date()).toISOString()
    },
    methods:
    {
      ...mapActions(["ChangeEntity"]),
      closeChat() {

        this.chatOpened = false
      }, openChat() {

        this.chatOpened = true
      },
      selectSite(n, id) {
        this.selected = n
        this.ChangeEntity(id)
      }
    },
    components: {
      SideNav, ChatComponent/*, TopNav*/
    }
  }
</script>