<template>
    <div class="  bg-gray-50  dark:bg-gray-900">
        <div class="hidden   dark:border-gray-50 mt-2">
            <h4 class="   text-left mb-2 text-xl tracking-tight font-bold text-gray-600 dark:text-white">
                datasource_id = {{ datasource_id }}
            </h4>
        </div>

        <B4FForm :record="record" :template="template" :countries="countries" :custom-action="customAction"
            :title="displayTitle()" :subtitle="displaySubTitle()" @generate="OnGenerateSheet"
            @generate-pdf="onGeneratePDFReport" @change-field-parameter="changeFieldParameter" @display="OnDisplaySheet"
            @change-view="onChangeView" @manage-button-click="manageButtonClick" @manage-field="modalFieldOpen"
            @add-field="modalAddFieldOpen" @delete-field="onDeleteField" @save="onSave" @delete="onDelete"
            @cancel="onCancel" @select-image-modal-open="onSelectImageModalOpen"
            @manage-postal-address="modalPostalAddressOpen" @value-changed="onValueChanged" @action="onAction" />

        <SelectImageModal :current-in="currentImageIndex" @close="OnSelectImageModalClose" @select-image="onSelectImage"
            @add-image="OnAddImage" @change-root="onChangeRoot" />
        <GenerateSheetModal @close="OnGenerateSheetClose" @generate="OnGenerateSheetGenerate"
            @confirm="OnGenerateSheetConfirm" />
        <ModalField :config="empty" @close="modalFieldClose" @save="modalFieldSave" @delete="modalFieldDelete" />
        <PostalAddressModal :input="inputAddress" @close="OnPostalAddressClose" @confirm="OnPostalAddressConfirm" />
        <SupplierModal :input="inputSupplier" @close="OnSupplierClose" @confirm="OnSupplierConfirm" />
        <AttributModal :input="inputAttribut" @close="OnAttributClose" @confirm="OnAttributConfirm" />

        <WildGameModal :input="inputWildGame" @close="OnWildGameClose" @confirm="OnWildGameConfirm" />


        <ContactManagementModal :input="inputContact" @close="OnContactManagementClose"
            @confirm="OnContactManagementConfirm" />

        <div class="hidden">
            <hr>
            {{ record }}
            <hr>
            {{ template }}
        </div>
        <div id="alert-form-error" class="opacity-0 hidden  z-50 fixed top-2 z-50 px-3 w-full flex justify-center ">
            <div class="border  border-red-700 max-w-5xl flex items-center p-4 mb-4 text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert">
                <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 20 20">
                    <path
                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span class="sr-only">Info</span>
                <div class="ms-3 text-sm font-medium px-6 " v-html="message_alert" />
                <button type="button"
                    class="ms-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"
                    data-dismiss-target="#alert-form-error" aria-label="Close">
                    <span class="sr-only">Close</span>
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                </button>
            </div>
        </div>
        <div id="alert-form-success" class="opacity-0 hidden  z-50 fixed top-2 z-50 px-3 w-full flex justify-center ">
            <div class="border  border-green-700 max-w-5xl flex items-center p-4 mb-4 text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
                role="alert">
                <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 20 20">
                    <path
                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span class="sr-only">Info</span>
                <div class="ms-3 text-sm font-medium px-6 ">
                    Opération réussie
                </div>
                <button type="button"
                    class="ms-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"
                    data-dismiss-target="#alert-success-error" aria-label="Close">
                    <span class="sr-only">Close</span>
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    /*
 Une erreur est intervenue lors de la sauvegarde <a href="#"
                        class="font-semibold underline hover:no-underline">Pour plus d'information</a>. Ré-essayez plus
                    tard.

    */
    import { v4 as uuidv4 } from 'uuid';
    import B4FForm from '@/components/FormComponent.vue'
    import service from "@/services/FullService.vue"
    import SelectImageModal from '@/components/modals/SelectImageModal.vue'
    import GenerateSheetModal from '@/components/modals/GenerateSheetModal.vue'

    import Handlebars from "handlebars";
    import PostalAddressModal from '@/components/modals/PostalAddressModal.vue'

    import ContactManagementModal from '@/components/modals/ContactManagementModal.vue'
    import SupplierModal from '@/components/modals/SupplierModal.vue'
    import AttributModal from '@/components/modals/AttributModal.vue'

    import WildGameModal from '@/components/modals/WildGameModal.vue'

    import ModalField from "@/components/modals/ManageFieldEditModal.vue"
    import { unflatten, flatten } from 'flat'
    import { Modal, Dismiss } from 'flowbite';

    import { mapGetters } from "vuex";
    export default {
        name: 'FormView',
        components: {
            B4FForm, SelectImageModal, GenerateSheetModal, ModalField, SupplierModal, PostalAddressModal, AttributModal, WildGameModal, ContactManagementModal
        },
        data() {
            return {
                config: {},
                record: {},
                countries: [],
                customAction: {
                    type: "",
                    timestamp: "",
                    attribut: "",
                    value: ""
                },


                datasource_id: null,


                back: "",
                message_alert: "",
                dismiss: null,
                success: null,
                category: null,
                empty: null,
                currentImageField: null,
                inputAddress: {},
                inputContact: {},
                inputAttribut: {},
                inputSupplier: {},
                inputWildGame: {},
                currentImageIndex: null,
                template: {},
                surveyModal: null,
                generateSheetModal: null,
                modalField: null,
                modalAttribut: null,
                modalWildGame: null,
                modalSupplier: null,
                modalPostalAddress: null,
                modalContentManagement: null,
                lg: "FR",
                title: "",
                button: ""
            }
        },

        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        watch:
        {
            '$route.params.rid': function () {

                // console.log("FORMVIEW ----------------- WATCH RID")
                //        this.loadPageRecord()


            },
            '$route.params.id': function () {

                // console.log("FORMVIEW ----------------- WATCH ID")

            },
            '$route.query.page': function () {
                this.back = this.$route.query.page;
                //  console.log("FORMVIEW ----------------- WATCH ROUTE")
                this.loadPageRecord()
            },
            category: function () {
                //   console.log("FORMVIEW ----------------- WATCH CATEGORY - loadPageConfig")
                this.loadPageConfig()
            },
            config: function (val) {
                //  console.log("FORMVIEW ----------------- WATCH CONFIG")
                if (val) {
                    if (val.title) this.title = val.title[this.lg];
                    if (val.button) this.button = val.button[this.lg];

                }
            },
            template: function () {
                //   console.log("FORMVIEW ----------------- WATCH TEMPLATE")
            }
        },
        async mounted() {
            //  console.log("FORMVIEW ------------------------ MOUNTED")
            if (this.user == undefined) {
                //this.$router.push("/login");
                window.location = "/login"
                return;
            }
            //  if (this.$route.params.id == "batches") {
            //      //this.$router.push("/login");
            //      window.location = "/admin/batches/" + this.$route.params.rid
            //      return;
            //  }

            if (this.$route.query.back != undefined) {
                this.back = this.$route.query.back;
            }

            const modalEl = document.getElementById('select-image-modal');
            this.surveyModal = new Modal(modalEl, {
                placement: 'center'
            });

            const modalEl2 = document.getElementById('generate-sheet-modal');
            this.generateSheetModal = new Modal(modalEl2, {
                placement: 'center'
            });

            const modalEl3 = document.getElementById('manage-field-edit-modal');
            this.modalField = new Modal(modalEl3, {
                placement: 'center'
            });
            const modalEl4 = document.getElementById('create-postal-address-modal');
            this.modalPostalAddress = new Modal(modalEl4, {
                placement: 'center'
            });


            const modalEl5 = document.getElementById('create-contact-modal');
            this.modalContentManagement = new Modal(modalEl5, {
                placement: 'center'
            });


            const modalEl6 = document.getElementById('create-supplier-modal');
            this.modalSupplier = new Modal(modalEl6, {
                placement: 'center'
            });


            const modalEl7 = document.getElementById('create-attribut-modal');
            this.modalAttribut = new Modal(modalEl7, {
                placement: 'center'
            });



            const modalEl8 = document.getElementById('show-wild-game-modal');
            this.modalWildGame = new Modal(modalEl8, {
                placement: 'center'
            });


            await this.loadCountries()

            // options object


            //  console.log("Load")

            this.category = null;
            //
            // 

            if (this.$route.params.id != undefined) {

                this.datasource_id = await service.getDatasourceId(this.$route.params.id, this.user?.token)

            }

            await this.loadPageRecord()


            if (this.$route.params.id == "product-suppliers") {
                this.template = {
                    source_id: "661857eb2cdd7ea01681abe8"
                }
            }



            //   console.log("FORMVIEW ------------------------ MOUNTED -- END")
        },
        methods: {
            displayConfirmation() {
                console.log("displayAlert ----------------------------------------------------------")

                // target element that will be dismissed
                const $targetEl = document.getElementById('alert-form-success');

                // optional trigger element
                const $triggerEl = null

                $targetEl.classList.add('opacity-100');
                $targetEl.classList.remove('opacity-0');
                $targetEl.classList.remove('hidden');
                const options = {
                    transition: 'transition-opacity',
                    duration: 5000,
                    timing: 'ease-out',

                    // callback functions
                    onHide: (context, targetEl) => {
                        console.log('element has been dismissed')
                        console.log(targetEl)
                    }
                };

                // instance options object
                const instanceOptions = {
                    id: 'alert-form-success',
                    override: true
                };
                if (this.sucess == undefined)
                    this.sucess = new Dismiss($targetEl, $triggerEl, options, instanceOptions);
                this.sucess.hide()
            },

            displayAlert() {
                console.log("displayAlert ----------------------------------------------------------")

                // target element that will be dismissed
                const $targetEl = document.getElementById('alert-form-error');

                // optional trigger element
                const $triggerEl = null

                $targetEl.classList.add('opacity-100');
                $targetEl.classList.remove('opacity-0');
                $targetEl.classList.remove('hidden');
                const options = {
                    transition: 'transition-opacity',
                    duration: 2500,
                    timing: 'ease-out',

                    // callback functions
                    onHide: (context, targetEl) => {
                        console.log('element has been dismissed')
                        console.log(targetEl)
                    }
                };

                // instance options object
                const instanceOptions = {
                    id: 'alert-form-error',
                    override: true
                };
                if (this.dismiss == undefined)
                    this.dismiss = new Dismiss($targetEl, $triggerEl, options, instanceOptions);
                this.dismiss.hide()
            },
            modalFieldClose: function () {
                this.modalField.hide();
            },
            OnPostalAddressClose: function () {
                this.modalPostalAddress.hide();
            },
            OnSupplierClose: function () {
                this.modalSupplier.hide();
            },
            OnAttributClose: function () {
                this.modalAttribut.hide();
            },

            OnWildGameClose: function () {
                this.modalWildGame.hide();
            },


            OnContactManagementClose: function () {
                this.modalContentManagement.hide();
            },




            OnWildGameConfirm: function () {
                alert("todo")
            },

            manageButtonClick: async function (event) {
                if (event.action == "add-user") {
                    let inputContact = {
                        id: null,
                        civility: null,
                        firstname: null,
                        lastname: null,
                        email: null,
                        phone: null,
                        mobile: null,
                        role: null
                    }

                    this.inputContact = inputContact;


                    this.modalContentManagement.show();
                    return;

                }



                if (event.action == "add-attribut") {

                    let inputAttribut =

                    {
                        id: null,
                        mode: "new",
                        name: { FR: null, EN: null },
                        description: { FR: null, EN: null },
                        default: null,
                        linked: null,
                        type: "string"
                    }

                    this.inputAttribut = inputAttribut;


                    this.modalAttribut.show();
                    return;

                }





                if (event.action == "add-address") {

                    let inputAddress =

                    {
                        id: null,
                        building: null,
                        street: null,
                        code: null,
                        city: null,
                        country: null,
                        for: null,
                        phone: null,
                        siret: null,
                        gs1_gln: null,
                        is_main_site: null
                    }

                    this.inputAddress = inputAddress;


                    this.modalPostalAddress.show();
                    return;

                }


                if (event.action == "add-supplier") {

                    let inputSupplier =

                    {
                        id: null,
                        name: this.record?.name,
                        currency: "€",
                        reference: null,
                        price: null,
                        quantity: null,
                        supplier: {}
                    }

                    this.inputSupplier = inputSupplier;


                    this.modalSupplier.show();
                    return;

                }


                if (event.action == "edit") {

                    let inputAddress =

                    {
                        id: event.data._id,
                        building: event.data.building,
                        street: event.data.street,
                        code: event.data.code,
                        city: event.data.city,
                        country: event.data.country,
                        for: event.data.for,
                        phone: event.data.phone,
                        siret: event.data?.siret,
                        gs1_gln: event.data?.gs1_gln,
                        is_main_site: event.data?.is_main_site
                    }

                    this.inputAddress = inputAddress;


                    this.modalPostalAddress.show();
                    return;
                }

                if (event.action == "edit-user") {

                    let inputContact =

                    {
                        id: event.data.id,
                        civility: event.data.civility,
                        firstname: event.data.firstname,
                        lastname: event.data.lastname,
                        email: event.data.email,
                        phone: event.data.phone,
                        mobile: event.data.mobile,
                        role: event.data.role
                    }

                    this.inputContact = inputContact;
                    console.log("edit-user edit-user edit-user edit-user edit-user edit-user edit-user edit-user edit-user edit-user edit-user ")
                    console.log(inputContact)
                    this.modalContentManagement.show();
                    return;
                }

                if (event.action == "edit-supplier") {

                    let inputSupplier =
                    {
                        id: event.data._id,
                        name: event.data.name,


                        quantity: event.data.quantity,
                        price: event.data.price,
                        currency: event.data.currency,
                        url: event.data.url,
                        reference: event.data.reference,
                        supplier: event.data.supplier
                    }

                    this.inputSupplier = inputSupplier;
                    this.modalSupplier.show();
                    return;
                }
                if (event.action == "edit-attribute") {

                    let inputAttribut =

                    {
                        id: event.data.id,
                        mode: "edit",
                        name: event.data.name,
                        description: event.data.description,
                        default: event.data.default,
                        linked: null,
                        type: event.data.type
                    }


                    this.inputAttribut = inputAttribut;


                    this.modalAttribut.show();
                    return;
                }



                if (event.action == "show-wild-game") {
 
                    let inputWildGame =

                    {
                        id: event.data.id,
                        mode: "edit",
                        name: event.data.name,
                        veto_photos: event.data.veto_photos,
                        nemrod_photos: event.data.nemrod_photos,

                        description: event.data.description,
                        default: event.data.default,
                        linked: null,
                        type: event.data.type
                    }


                    this.inputWildGame = inputWildGame;


                    this.modalWildGame.show();
                    return;
                }

                if (event.action == "delete") {



                    // let collection = "company-addresses"    

                    let collection = "data-sources/668e6a7467ca9aa206c55dda/records"

                    await service.deleteRecord(collection, this.user?.token, event.data._id)
                    let index = this.record?.addresses?.findIndex(function (a) { return a._id == event.data._id })
                    this.record?.addresses?.splice(index, 1);


                    //     let response = await service.deleteRecord("company-addresses", this.user?.token, event.data._id)
                    //     console.log(response)

                    return;
                }


                if (event.action == "delete-user") {

                    let collection = "data-sources/6642fa56a2b8d03824a74c9c/records"
                    // let collection ="contacts"
                    await service.deleteRecord(collection, this.user?.token, event.data._id)
                    let index = this.record.contacts.findIndex(function (a) { return a._id == event.data._id })
                    this.record.contacts.splice(index, 1);

                    //    let response = await service.deleteRecord(collection, this.user?.token, event.data._id)
                    //    console.log(response)
                    return;
                }

                if (event.action == "delete-attribute") {

                    let index = this.record.items.findIndex(function (a) { return a._id == event.data._id })
                    this.record.items.splice(index, 1);
                    return;
                }

                if (event.action == "delete-supplier") {
                    let index = this.record?.items?.findIndex(function (a) { return a._id == event.data._id })
                    this.record?.items?.splice(index, 1);
                    let source_id = "6655c723ce02a05e92113b9d"; // Liste des Fourniseurs
                    let collection = "data-sources/" + source_id + '/records'
                    let response = await service.deleteRecord(collection, this.user?.token, event.data._id)
                    console.log(response)
                    return;
                }

            },
            OnAttributConfirm: async function (item) {

                console.log(item?.id)
                if (this.record?.items == undefined)
                    this.record.items = []
                if (item.mode == "edit") {
                    for (let i = 0; i < this.record.items.length; i++) {
                        if (this.record.items[i]._id == item.id) {
                            this.record.items[i] = item

                        }
                    }
                }
                if (item.mode == "new") {


                    let bfound = false;
                    for (let i = 0; i < this.record.items.length; i++) {


                        if (this.record.items[i].id == item.id) {
                            bfound = true

                        }
                    }
                    if (bfound) {
                        alert("Error The attribute " + item.id + " existe déja")
                        return;
                    }

                    let index = this.record.items.push(item)
                    console.log(index)
                }
                this.modalAttribut.hide();
            },
            OnSupplierConfirm: async function (item) {
                console.log(item?.id)

                if (item?.id == undefined) {

                    if (this.record.items == undefined)
                        this.record.items = []
                    let index = this.record.items.push(item)
                    item.company_id = this.record?._id;

                    let source_id = "6655c723ce02a05e92113b9d"; // Liste des Fourniseurs
                    let collection = "data-sources/" + source_id + '/records'

                    //  item.name = this.record.name;

                    delete item.id
                    item.product_id = this.record._id;



                    let response = await service.addRecord(collection, this.user?.token, item)
                    if (response != undefined) {
                        if (response.insertedId != undefined) {
                            this.record.items[index].id = index;
                        }
                        else {
                            console.log("Error add")
                            console.log(response)
                        }
                    }
                }
                else {

                    for (let i = 0; i < this.record.items.length; i++) {
                        if (this.record.items[i]._id == item.id) {
                            this.record.items[i] = item
                        }
                    }
                    let source_id = "6655c723ce02a05e92113b9d"; // Liste des Fourniseurs
                    let collection = "data-sources/" + source_id + '/records'
                    let response = await service.updateRecord(collection, item.id, this.user?.token, item)
                    console.log(response)
                }
                this.modalSupplier.hide();
            },
            OnPostalAddressConfirm: async function (item) {
                if (item?.id == undefined) {

                    item.debug = this.$route.params
                    if (this.record.addresses == undefined)
                        this.record.addresses = []
                    let index = this.record.addresses.push(item)

                    let collection = "data-sources/668e6a7467ca9aa206c55dda/records"
                    // let collection = "company-addresses"
                    item.company_id = this.$route.params.rid;

                    let response = await service.addRecord(collection, this.user?.token, item)

                    if (response != undefined) {
                        if (response.insertedId != undefined) {
                            this.record.addresses[index].id = index;
                        }
                        else {
                            console.log("Error")
                        }
                    }
                }
                else {

                    for (let i = 0; i < this.record.addresses.length; i++) {
                        if (this.record.addresses[i]._id == item.id) {
                            this.record.addresses[i] = item
                        }
                    }
                    let collection = "data-sources/668e6a7467ca9aa206c55dda/records"
                    // let collection = "company-addresses"
                    let response = await service.updateRecord(collection, item.id, this.user?.token, item)
                    console.log(response)
                }
                this.modalPostalAddress.hide();
            },
            OnContactManagementConfirm: async function (item) {
                console.log("OnContactManagementConfirm OnContactManagementConfirm OnContactManagementConfirm OnContactManagementConfirm OnContactManagementConfirm OnContactManagementConfirm OnContactManagementConfirm OnContactManagementConfirm OnContactManagementConfirm OnContactManagementConfirm OnContactManagementConfirm")
                console.log(item)
                if (item?.id == undefined) {
                    if (this.record.contacts == undefined) this.record.contacts = [];
                    let index = this.record.contacts.push(item)
                    item.company_id = this.record?._id;
                    console.log("index = ", index)

                    let collection = "data-sources/6642fa56a2b8d03824a74c9c/records"
                    // let collection ="contacts"



                    let response = await service.addRecord(collection, this.user?.token, item)
                    console.log("Add Contacts Add Contracs ", response.id)
                    console.log(JSON.stringify(response))
                    console.log(JSON.stringify(this.record.contacts))
                    if (response != undefined) {
                        if (response.id != undefined) {
                            this.record.contacts[index - 1].id = response.id;
                        }
                        else {
                            console.log("Error")
                        }
                    }
                    console.log(JSON.stringify(this.record.contacts))
                }
                else {
                    for (let i = 0; i < this.record.contacts.length; i++) {
                        if (this.record.contacts[i].id == item.id) {
                            this.record.contacts[i] = item
                        }
                    }
                    let collection = "data-sources/6642fa56a2b8d03824a74c9c/records"
                    // let collection ="contacts"
                    let response = await service.updateRecord(collection, item.id, this.user?.token, item)
                    console.log(response)
                }
                this.modalContentManagement.hide();
            },
            modalFieldDelete: async function (field) {
                if (confirm("Delete ?" + field.id + " (" + field.type + ")")) {

                    if (field.type == "section") {
                        for (let i = 0; i < this.template?.children?.length; i++) {
                            let level1 = this.template?.children[i]
                            for (let j = 0; j < level1?.body?.length; j++) {
                                let level2 = level1.body[j]
                                if (field.id == level2.id) {
                                    this.template.children.body.splice(i, j);
                                }
                            }
                        }


                        await service.updateRecord("ui-forms", this.template._id, this.user?.token, this.template)
                        this.modalField.hide();
                        return
                    }
                    else if (field.type == "zone") {

                        for (let i = 0; i < this.template?.children?.length; i++) {
                            let level1 = this.template?.children[i]
                            for (let j = 0; j < level1?.body?.length; j++) {
                                let level2 = level1.body[j]
                                for (let k = 0; k < level2?.items?.length; k++) {
                                    let level3 = level2.items[k]

                                    if (field.id == level3.id) {
                                        this.template.children[i].body[j].items.splice(k, 1)

                                    }
                                }
                            }
                        }


                        await service.updateRecord("ui-forms", this.template._id, this.user?.token, this.template)
                        this.modalField.hide();
                        return;
                    }
                    else {
                        for (let i = 0; i < this.template?.children?.length; i++) {

                            let level1 = this.template?.children[i]

                            for (let j = 0; j < level1?.body?.length; j++) {

                                let level2 = level1.body[j]
                                for (let k = 0; k < level2?.items?.length; k++) {
                                    let level3 = level2.items[k]

                                    for (let l = 0; l < level3?.items?.length; l++) {
                                        let level4 = level3.items[l]

                                        if (field.id == level4.id) {
                                            delete this.template.children[i].body[j].items[k].items.splice(l, 1)
                                        }
                                    }
                                }

                                //    if (this.template.items[i].items[j].id == field.id) {
                                //        this.template.items[i].items[j].label.FR = "philippe"
                                //    }

                            }
                        }
                        await service.updateRecord("ui-forms", this.template._id, this.user?.token, this.template)
                        this.modalField.hide();
                        return;
                    }
                }
            },
            modalFieldSave: async function (field) {
                if (field.id == 'new') {
                    if (field.type == "section") {
                        for (let i = 0; i < this.template?.children?.length; i++) {
                            let level1 = this.template?.children[i]
                            if (field.tab == level1.id) {
                                for (let j = 0; j < level1?.body?.length; j++) {

                                    let level2 = level1.body[j]

                                    if (level2.id == field.after) {
                                        let section =
                                        {
                                            id: uuidv4(),
                                            title: {
                                                "FR": field.attributes.name,
                                                "EN": "EN--" + field.attributes.name
                                            },
                                            class: field.attributes.class,
                                            items: []
                                        }
                                        level1.body.push(section)
                                    }
                                }
                            }
                        }
                    }
                    if (field.type == "zone") {
                        for (let i = 0; i < this.template?.children?.length; i++) {
                            let level1 = this.template?.children[i]
                            if (field.tab == level1.id) {

                                for (let j = 0; j < level1?.body?.length; j++) {

                                    let level2 = level1.body[j]
                                    if (level2.id == field.section) {
                                        for (let k = 0; k < level2?.items?.length; k++) {

                                            let level3 = level2.items[k]

                                            if (level3.id == field.after) {
                                                let zone =
                                                {
                                                    id: uuidv4(),
                                                    title: {
                                                        "FR": field.attributes.name,
                                                        "EN": "EN--" + field.attributes.name
                                                    },
                                                    class: field.attributes.class,
                                                    items: []
                                                }
                                                level2.items.push(zone)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    this.modalField.hide();
                    return
                }

                //  this.modalField.hide();
                //  return

                if (field.type == "section") {
                    for (let i = 0; i < this.template?.children?.length; i++) {

                        let level1 = this.template?.children[i]

                        for (let j = 0; j < level1?.body?.length; j++) {

                            let level2 = level1.body[j]

                            if (field.id == level2.id) {
                                if (this.template.children[i].body[j].title == undefined)
                                    this.template.children[i].body[j].title = {}
                                this.template.children[i].body[j].title[this.lg] = field.attributes.name;
                                this.template.children[i].body[j].class = field.attributes.class;

                            }



                            //    if (this.template.items[i].items[j].id == field.id) {
                            //        this.template.items[i].items[j].label.FR = "philippe"
                            //    }

                        }
                    }
                    await service.updateRecord("ui-forms", this.template._id, this.user?.token, this.template)

                    this.modalField.hide();
                    return
                }
                else if (field.type == "zone") {

                    for (let i = 0; i < this.template?.children?.length; i++) {
                        let level1 = this.template?.children[i]
                        for (let j = 0; j < level1?.body?.length; j++) {
                            let level2 = level1.body[j]
                            for (let k = 0; k < level2?.items?.length; k++) {
                                let level3 = level2.items[k]

                                if (field.id == level3.id) {
                                    if (this.template.children[i].body[j].items[k].title == undefined)
                                        this.template.children[i].body[j].items[k].title = {}
                                    this.template.children[i].body[j].items[k].title[this.lg] = field.attributes.name;
                                    this.template.children[i].body[j].items[k].class = field.attributes.class;

                                }
                            }
                        }
                    }
                    await service.updateRecord("ui-forms", this.template._id, this.user?.token, this.template)
                    this.modalField.hide();
                    return;
                }
                else {
                    for (let i = 0; i < this.template?.children?.length; i++) {

                        let level1 = this.template?.children[i]

                        for (let j = 0; j < level1?.body?.length; j++) {
                            let level2 = level1.body[j]
                            for (let k = 0; k < level2?.items?.length; k++) {
                                let level3 = level2.items[k]


                                if (field.inside == level3.id) {

                                    let newfield = field.attributes


                                    newfield.id = field.id
                                    newfield.type = field.type
                                    this.template.children[i].body[j].items[k].items.push(newfield)

                                    //PV Aligner le format de la structure
                                    return;
                                }


                                for (let l = 0; l < level3?.items?.length; l++) {
                                    let level4 = level3.items[l]



                                    if (field.after == level4.id) {

                                        let arr = this.template.children[i].body[j].items[k].items;

                                        //PV Aligner le format de la structure
                                        field.attributes.id = field.id
                                        field.attributes.type = field.type



                                        let newItems = [
                                            // part of the array before the specified index
                                            ...arr.slice(0, l + 1),
                                            // inserted item
                                            field.attributes,
                                            // part of the array after the specified index
                                            ...arr.slice(l + 1)
                                        ]
                                        this.template.children[i].body[j].items[k].items = newItems
                                        await service.updateRecord("ui-forms", this.template._id, this.user?.token, this.template)

                                        this.modalField.hide();
                                        return;

                                    }
                                    else
                                        if (field.id == level4.id) {
                                            if (field?.attributes?.source_id)
                                                this.template.children[i].body[j].items[k].items[l].source_id = field.attributes.source_id
                                            if (this.template.children[i].body[j].items[k].items[l].label == undefined)
                                                this.template.children[i].body[j].items[k].items[l].label = {}

                                            this.template.children[i].body[j].items[k].items[l].label = field.attributes.label;
                                            this.template.children[i].body[j].items[k].items[l].class = field.attributes.class;
                                            this.template.children[i].body[j].items[k].items[l].data = field.attributes.data;
                                            if (field.attributes?.source != undefined) this.template.children[i].body[j].items[k].items[l].source = field.attributes.source;


                                            if (field.attributes?.format != undefined) this.template.children[i].body[j].items[k].items[l].format = field.attributes.format;
                                            if (field.attributes?.unit != undefined) this.template.children[i].body[j].items[k].items[l].unit = field.attributes.unit;
                                            if (field.attributes?.collection != undefined) this.template.children[i].body[j].items[k].items[l].collection = field.attributes.collection;
                                            if (field.attributes?.filter != undefined) this.template.children[i].body[j].items[k].items[l].filter = field.attributes.filter;

                                            if (field.attributes?.template != undefined) this.template.children[i].body[j].items[k].items[l].template = field.attributes.template;


                                            if (field.attributes?.subdata != undefined) this.template.children[i].body[j].items[k].items[l].subdata = field.attributes.subdata;

                                            if (field.attributes?.image_url != undefined) this.template.children[i].body[j].items[k].items[l].image_url = field.attributes.image_url;
                                            if (field.attributes?.image_class != undefined) this.template.children[i].body[j].items[k].items[l].image_class = field.attributes.image_class;
                                            if (field.attributes?.text_class != undefined) this.template.children[i].body[j].items[k].items[l].text_class = field.attributes.text_class;



                                        }
                                }
                            }

                            //    if (this.template.items[i].items[j].id == field.id) {
                            //        this.template.items[i].items[j].label.FR = "philippe"
                            //    }

                        }
                    }
                }
                await service.updateRecord("ui-forms", this.template._id, this.user?.token, this.template)
                this.modalField.hide();
            },
            async onGeneratePDFReport() {
                //   let id = "6665ce28fcec2d05a4a97fbb"
                let id = "664303fda2b8d03824a74caa"
                let url = "data-sources/" + id + "/pdf/" + this.$route.params.rid
                let records = await service.getData(url, this.user?.token, {});
                let blob = new Blob([records], { type: 'application/pdf' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                console.log(link.href)
                link.download = "file.pdf"
                link._target = '_blank'
                link.click();
            },
            generateItem: function (type) {

                if (type == 'text')
                    return {
                        "id": uuidv4(),
                        "label": {
                            "FR": "Titre ......",
                            "EN": "Titre ......"
                        },
                        "placeholder": {
                            "EN": "",
                            "FR": ""
                        },
                        "data": "attribute",
                        "type": "text",
                        "class": "w-6/12   text-gray-800 justify-start",
                        "format": "text",
                        "unit": ""
                    }


                if (type == 'blank')
                    return {
                        "id": uuidv4(),

                        "type": "blank",
                        "class": "w-4/12"
                    }


                if (type == 'display')
                    return {
                        "id": uuidv4(),
                        "label": {
                            "EN": "Title ...",
                            "FR": "Titre ..."
                        },
                        "template": "",
                        "type": "display",
                        "format": "text",
                        "class": "w-full"
                    }


                if (type == "selectCollection")
                    return {
                        "id": uuidv4(),
                        "label": {
                            "FR": "Titre ......",
                            "EN": "Titre ......"
                        },
                        "placeholder": {
                            "EN": "",
                            "FR": ""
                        },
                        "filters": {},
                        "data": "attribute",
                        "mode": "collection",
                        "collection": "",
                        "source": "",
                        "type": "selectCollection",
                        "class": "w-6/12  text-gray-800 justify-start"
                    }


                if (type == "tag")
                    return {
                        "id": uuidv4(),
                        "label": {
                            "FR": "Titre ......",
                            "EN": "Titre ......"
                        },
                        "placeholder": {
                            "EN": "",
                            "FR": ""
                        },
                        "data": "attribute_tag",
                        "type": "tag",
                        "source": "material-type",
                        "class": "w-6/12  text-gray-800 justify-start"
                    }



                return {
                    "id": uuidv4(),
                    "label": {
                        "FR": "Titre ......",
                        "EN": "Titre ......"
                    },
                    "placeholder": {
                        "EN": "",
                        "FR": ""
                    },
                    "data": "attribute",
                    "type": "text",
                    "class": "w-6/12   text-gray-800 justify-start",
                    "format": "text",
                    "unit": ""
                }
            },
            modalAddFieldOpen: function (item) {
                console.log(typeof item);
                console.log(typeof item.type);
                console.log(item);
                console.log(item.type);
                if (item.type == '') {

                    return
                }
                this.empty = this.generateItem(item.type);
                this.empty.after = item.after?.id;
                this.empty.inside = item.inside
                this.modalField.show();
            },
            modalFieldOpen: function (item) {
                this.empty = item;
                this.empty.after = null;
                this.modalField.show();
            },
            modalPostalAddressOpen: function (config) {
                this.empty = config;
                this.modalPostalAddress.show();
            },
            modalContactManagementOpen: function (config) {
                this.empty = config;
                this.modalContactManagement.show();
            },
            onDeleteField: async function (field) {
                for (let i = 0; i < this.template?.children?.length; i++) {

                    let level1 = this.template?.children[i]

                    for (let j = 0; j < level1?.body?.length; j++) {
                        let level2 = level1.body[j]
                        for (let k = 0; k < level2?.items?.length; k++) {
                            let level3 = level2.items[k]

                            for (let l = 0; l < level3?.items?.length; l++) {
                                let level4 = level3.items[l]


                                if (field.id == level4.id) {

                                    this.template?.children[i].body[j].items[k].items.splice(l, 1);
                                    await service.updateRecord("ui-forms", this.template._id, this.user?.token, this.template)
                                    return;
                                }
                            }
                        }


                    }
                }


            },
            changeFieldParameter: function (/*field*/) {


            },
            onValueChanged(event) {

                let r = flatten(this.record)
                r[event.id] = event.value
                this.record = unflatten(r)


                //TODO : Vérrue pour "Actors"
                if (event.id == "team_id") {
                    //On change le job_ud si changement de team_id

                    if (event.mode == 0) this.record.job_id = ""

                    this.customAction =
                    {
                        timestamp: new Date(),
                        attribut: "job_id",
                        value: ""
                    }
                }

            },

            async onAction(event) {


                switch (event.type) {

                    case 'change-pincode':
                        {
                            if (event.pincode.length != 4) {
                                this.message_alert = this.$t("FORMVIEW_ERROR_PINCODE_LENGTH")
                                this.displayAlert()
                                return;
                            }
                            let item =
                            {
                                'id': event.id,
                                'pincode': event.pincode,
                            }
                            let response = await service.ChangeUserPincode(item, this.user?.token)


                            if (response.status == "OK") {
                                this.displayConfirmation()
                            }
                            else {
                                this.message_alert = this.$t("FORMVIEW_EXPECTED_ERROR")
                                this.displayAlert()
                            }
                        }
                        break;
                    case 'send-invitation':
                        {
                            let item =
                            {
                                'tenant': 'xx',
                                'email': event.email
                            }
                            let response = await service.sendInvitationMail(item, this.user?.token)


                            if (response.status == "OK") this.displayConfirmation()
                            else {
                                this.message_alert = this.$t("FORMVIEW_EXPECTED_ERROR")
                                this.displayAlert()
                            }
                        }
                        break;
                    case 'reset-password':
                        {

                            let item =
                            {
                                'tenant': 'xx',
                                'email': event.email
                            }
                            let response = await service.ResetPassword2(item, this.user?.token)

                            if (response?.status == "OK") this.displayConfirmation()
                            else {
                                this.message_alert = this.$t("FORMVIEW_EXPECTED_ERROR")
                                this.displayAlert()
                            }
                        }
                        break;

                    case 'activate-portal-user':
                        {

                            let item =
                            {
                                actor_id: event.actor_id,
                                first_name: event.first_name,
                                last_name: event.last_name,
                                login: event.login,
                                email: event.email
                            }
                            let response = await service.ActivateUser(item, this.user?.token)

                            if (response?.status == "OK") this.displayConfirmation()
                            else {
                                this.message_alert = this.$t("FORMVIEW_EXPECTED_ERROR")
                                this.displayAlert()
                            }

                            this.record['portal-state'] = "PENDING-ACTIVATION"
                        }

                        break;

                }
            },



            OnAddImage() {
                this.surveyModal.hide();
            },
            onChangeRoot(index) {
                this.currentImageIndex = index;
            },
            displayTitle() {
                if (this.template == undefined) return ""
                if (this.template.title == undefined) return ""
                if (this.lg == undefined) return ""

                Handlebars.registerHelper("dateFormat", function (options) {
                    //return options.fn(this);

                    if (options.fn(this) == undefined) return "-"
                    if (options.fn(this) == "") return "-"
                    if (options.fn(this) == "-") return "-"
                    let d = new Date(options.fn(this))
                    let m = ("0" + (d.getMonth() + 1)).slice(-2)
                    let day = ("0" + (d.getDate())).slice(-2)


                    return new Handlebars.SafeString(day + "/" + m + "/" + d.getFullYear());
                });



                Handlebars.registerHelper("datetimeFormat", function (options) {
                    //return options.fn(this);

                    if (options.fn(this) == undefined) return ""
                    if (options.fn(this) == "") return ""
                    let d = new Date(options.fn(this))
                    let m = ("0" + (d.getMonth() + 1)).slice(-2)
                    let day = ("0" + (d.getDay() + 1)).slice(-2)
                    let hour = ("0" + (d.getHours() + 1)).slice(-2)
                    let minute = ("0" + (d.getMinutes() + 1)).slice(-2)

                    return new Handlebars.SafeString(day + "/" + m + "/" + d.getFullYear() + " à " + hour + ":" + minute);


                });

                const template = Handlebars.compile(this.template?.title[this.lg]);
                let t = (template(this.record));
                //return  t + "";  
                return t; //this.template?.title[this.lg]  + "---"
            },
            displaySubTitle() {
                if (this.template == undefined) return ""
                if (this.template.title == undefined) return ""
                if (this.lg == undefined) return ""
                if (this.template?.subtitle == undefined) return ""


                const template = Handlebars.compile(this.template?.subtitle[this.lg]);
                let t = (template(this.record));
                return t + "";
            },
            OnGenerateSheetOld() {
                this.generateSheetModal.show()
            },
            OnDisplaySheet() {
                let url = "/admin/pdf/display/" + this.$route.params.rid
                window.location = url;
            },
            OnGenerateSheet(data) {
                //  window.location = "https://generate-pdf-seven.vercel.app/generate/"+ id
                // window.location = 
                let langage = "fr"
                let market = "EU"

                if (data.market == "eu-en") {
                    langage = "en"
                    market = "EU"

                }

                if (data.market == "us-en") {
                    langage = "en"
                    market = "US"

                }

                if (data.market == "au-en") {
                    langage = "en"
                    market = "AU"

                }

                let url = "/admin/pdf/" + this.$route.params.rid + "?market=" + market + "&langage=" + langage
                window.location = url;
                // window.open(url, this.$route.params.rid)

                //this.$router.push(url)

                // window.open("https://seashell-app-rrlf8.ondigitalocean.app/generate/" + this.$route.params.rid+"?market=" +data.market, this.$route.params.rid);
            },
            OnGenerateSheetClose() {
                this.generateSheetModal.hide()
            },
            OnGenerateSheetGenerate() {
                this.generateSheetModal.hide()
            },
            OnGenerateSheetConfirm() {
                this.generateSheetModal.hide()
            },
            onSelectFile(image) {
                this.record[this.currentImageField] =
                {
                    "name": image.name,
                    "url": "https://file-storage-ten.vercel.app/b4food/files/" + image.id
                }

                this.surveyModal.hide();
            },
            onSelectImage(image) {
                this.record[this.currentImageField] = image.id
                if (image.action == 'close') {

                    let timer = setTimeout(() => { clearTimeout(timer); this.surveyModal.hide(); }, 200)
                }
            },
            onSelectImageModalOpen: function (item) {
                this.currentImageField = item;
                this.currentImageIndex = null
                this.surveyModal.show();
            },
            OnSelectImageModalClose: function () {
                this.surveyModal.hide();
            },
            onCancel() {
                if (this.back) {
                    this.$router.push(this.back)
                    //   window.location = this.back;
                    return;
                }

                if (this.template) {
                    if (this.template.back) {

                        if (this.template.back.link) {
                            this.$router.push(this.template.back.link)
                            //   window.location = this.template.back.link;
                            return;
                        }

                    }
                }


                let url = "/admin/views/" + this.$route.params.id;
                window.location = url;
            },
            async onDelete(item) {
                //let collection = this.$route.params.id;


                let collection = "data-sources/" + this.template?.source_id + '/records'



                //let response =
                await service.deleteRecord(collection, this.user?.token, item._id)


                this.onCancel()
            },
            async onSave(item) {
                if (this.$route.params.rid == 'new') {

                    let collection = this.$route.params.id
                    if (this.template.endpoint != undefined)
                        collection = this.template.endpoint
                    if (collection != "select-values") { //A Revoir
                        if (this.$route.query.template) {
                            item.category = this.$route.query.template;
                        }
                        else {
                            //A revoir
                            item.category = this.$route.params.id
                        }


                    }

                    collection = "data-sources/" + this.template.source_id + '/records'

                    let response = await service.addRecord(collection, this.user?.token, item)



                    //  let response = await service.addRecord(collection, this.user?.token, item)
                    if (response != undefined) {
                        if (response.insertedId != undefined) {
                            //  this.$router.push("/admin/views/" + this.$route.params.id + "/" + response.insertedId)

                            console.log("SAVE -------------------------------------------")
                            console.log("SAVE -------------------------------------------")
                            console.log("SAVE -------------------------------------------")
                            this.$router.push("/admin/views/" + this.$route.params.id + "/" + response.insertedId)
                            // window.location = "/admin/views/" + this.$route.params.id + "/" + response.insertedId
                        }

                        else {
                            console.log("SAVE 2-------------------------------------------")
                            console.log("SAVE 2-------------------------------------------")
                            console.log("SAVE 2-------------------------------------------")

                            this.$router.push("/admin/views/" + this.$route.params.id + "/" + response._id)

                            // this.$router.push("/admin/views/" + this.$route.params.id + "/" + response._id)
                            // window.location = "/admin/views/" + this.$route.params.id + "/" + response._id
                        }
                    }
                    else {
                        this.message_alert = this.$t("FORMVIEW_EXPECTED_ERROR")
                        this.displayAlert()
                    }



                }
                else {

                    let itemunflatten = unflatten(item)
                    //let response =

                    //PV

                    let collection = "data-sources/" + this.template?.source_id + '/records'



                    await service.updateRecord(collection, this.$route.params.rid, this.user?.token, itemunflatten)

                    // await service.updateRecord(this.$route.params.id, this.$route.params.rid, this.user?.token, itemunflatten)

                }


            },

            async loadCountries() {
                let url = "data-sources/66bca5def254afd9ae336ac8/records"
                let records = await service.getData(url, this.user?.token, { limit: 300 });

                for (let i = 0; i < records.records.length; i++) {
                    records.records[i].flag = "fi-" + records.records[i].iso_2?.toLowerCase()
                }

                this.countries = records.records
            },


            async loadPageConfig() {
                //  console.log(" loadPageConfig  id", this.$route.params.id)
                //  console.log("loadPageConfig  rid", this.$route.params.rid)
                let category = this.category
                let template = this.$route.params.id
                if (this.$route.params.id == "tasks") {
                    // console.log("FORMVIEW ------------------------ loadPageConfig -- loadPageConfig")
                    // console.log("FormView 1", this.$route.params.rid)
                    console.log("loadPageConfig 1")
                    this.template = await service.getConfigurationDetails(template + "/" + this.$route.params.rid, category, this.user?.token)
                }
                else {

                    //   console.log("FormView 2", category)
                    //   console.log("FORMVIEW ------------------------ loadPageConfig -- loadPageConfig 2")
                    //  if ((category != undefined) && (this.$route.params.id == "raw-products"))
                    {
                        console.log("loadPageConfig 2")
                        let response = await service.getConfigurationDetails(template, category, this.user?.token)
                        console.log('template', response)
                        this.template = response


                        if (this.$route.params.rid == 'new') {
                            if (this.$route.query.template != undefined) category = this.$route.query.template


                            let image_id = "66bc4f1658e85d6ca6753171"
                            console.log("loadPageRecord loadPageRecord loadPageRecord loadPageRecord loadPageRecord loadPageRecord")
                            console.log(typeof this.template)
                            console.log(this.template)
                            console.log('------------')
                            if (this.template?.default != undefined) {
                                let keys = Object.keys(this.template?.default)
                                for (let k = 0; k < keys.length; k++) {
                                    if (keys[k] == "image_id") {
                                        image_id = this.template.default[keys[k]]
                                        if (image_id == undefined) image_id = "66bc4f1658e85d6ca6753171"
                                    }
                                }

                            }
                            let record = {}
                            record = {
                                "image_id": image_id,
                                "portal-state": "NOT-ACTIVE",
                                "user_id": null
                            }
                            this.record = record
                        }

                    }
                }
                return null;
            },
            async loadPageRecord() {
                let category = "";
                let record = {}
                if (this.$route.params.rid == 'new') {
                    if (this.$route.query.template != undefined) category = this.$route.query.template


                    let image_id = "66bc4f1658e85d6ca6753171"
                    console.log("loadPageRecord loadPageRecord loadPageRecord loadPageRecord loadPageRecord loadPageRecord")
                    console.log(typeof this.template)
                    console.log(this.template)
                    console.log('------------')
                    if (this.template?.default != undefined) {
                        let keys = Object.keys(this.template?.default)
                        for (let k = 0; k < keys.length; k++) {
                            if (keys[k] == "image_id") {
                                image_id = this.template.default[keys[k]]
                                if (image_id == undefined) image_id = "66bc4f1658e85d6ca6753171"
                            }
                        }

                    }

                    record = {
                        "image_id": image_id,
                        "portal-state": "NOT-ACTIVE",
                        "user_id": null
                    }
                }
                else {

                    if (this.datasource_id == undefined) {
                        alert('(this.datasource_id  == undefined)')
                        // this.$router.push("/error")
                        return;
                    }


                    let collection = "data-sources/" + this.datasource_id + '/records'

                    record = await service.getRecord(collection, this.$route.params.rid, this.user?.token)

                    //   console.log(JSON.stringify(record))

                    if (record != undefined) category = record.category
                    if (record?.template != undefined) category = record?.template
                    if (category == undefined) category = this.$route.query.template
                }
                this.category = category
                this.record = record
            },
            async onChangeView(item) {
                let url = "/admin/views/" + item.collection + "/" + item.record
                window.location = url
            }
        },

    }
</script>