<script>
    import axios from "axios";
    import description_inbound_milk from '@/templates/inbound-milk.json'

    export default {

        async translate(lg, text, token) {

            let url = "/api/b4food/translations";

            let response = await axios.post(url, {
                lg: lg,
                text: text

            }, {
                headers: {
                    "x-access-token": token,
                },
            });


            return response?.data
        },

        async getSideMenus(token) {
            let id = "668b8f04f8ac39985a59c476" //
            let url = "/api/b4food/data-sources/" + id + "/records";
            //   console.log("url =", url)
            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token,
                },
            });

            let sidemenus = null
            //    console.log(response?.data)
            if (response?.data?.records.length > 0)
                sidemenus = response?.data.records[0]

            return sidemenus

        },

        async getDatasourceId(id, token) {
            if (token == undefined) return null
            let url = "/api/b4food/data-sources?views=" + id;
            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token,
                },
            });
            if (response?.data?.records?.length > 0) {
                return response?.data?.records[0]._id
            }
            return null
        },


        async getReports(id, filters, token) {

            let url = "/api/b4food/aggregations/" + id;


            let response = await axios.post(url, {}, {
                headers: {
                    "x-access-token": token,
                },
            });

            return response?.data
        },
        async getItem3(id, token) {
            //   http://localhost:3004/api/b4food/data-sources/661855472cdd7ea01681abe4/records?limit=10
            //   http://localhost:3004/data-sources/661855472cdd7ea01681abe4/records/6644be5f3518cf74165c2d48
            if (token == undefined) return null;

            let url = "/api/b4food/data-sources/6644c5273518cf74165c2d57/records/" + id;
            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token,
                },
            });

            return response?.data
        },

        async getItem2(id, token) {

            if (token == undefined) return null;

            let url = "/api/b4food/data-sources/661855472cdd7ea01681abe4/records/" + id;
            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token,
                },
            });
            console.log("getItem2 getItem2 getItem2 getItem2 getItem2 getItem2 getItem2")
            console.log(JSON.stringify(response?.data))
            return response?.data
        },
        async getItem(id, token) {

            if (token == undefined) return null;

            let url = "/api/b4food/batches/" + id;


            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token,
                },
            });

            return response?.data
        },
        async getConfigurationDetails(id, category, token) {
            //   console.log("getConfigurationDetails 1", category)
            let form_id = "";
            let filter = null;

            if (id == "batches") {
                ///   form_id = "64ca37cfae9f4714d49d6068"
                //TODO   if (category == "product-wine") form_id = "64ca5743ae9f4714d49d6088"
            }

            if (id == "raw-products") {

                filter = { "category": category }
            }

            if (id == "products") {
                filter = { "category": category }
            }

            if (id == "product-suppliers") {
                filter = { "category": category }
            }

            /*
            if (id == "events") {
                if (category == "events_enr_cont_01a") form_id = "64ca52d5ae9f4714d49d607c"
                if (category == "events_enr_cont_03a") form_id = "64ca52d5ae9f4714d49d607c"
                if (category == "events_enr_cont_09a") form_id = "64ca538dae9f4714d49d607d"
                if (category == "events_enr_cont_09c") form_id = "64ca541fae9f4714d49d607e"
                if (category == "events_enr_cont_10a") form_id = "64ca5456ae9f4714d49d607f"
                if (category == "events_enr_cont_11c") form_id = "64ca554eae9f4714d49d6080"
                if (category == "events_enr_cont_14") form_id = "64ca5586ae9f4714d49d6081"
                if (category == "events_enr_cont_23") form_id = "64ca55b4ae9f4714d49d6082"
                if (category == "events_enr_cont_25") form_id = "64ca55e2ae9f4714d49d6083"
                if (category == "events_enr_nett_07") form_id = "64ca561aae9f4714d49d6084"
                if (category == "events_enr_nett_09") form_id = "64ca5684ae9f4714d49d6086"
                if (category == "events_enr_nett_12") form_id = "64ca56e3ae9f4714d49d6087"
                if (category == "events_enr_nett_08") form_id = "64ca564bae9f4714d49d6085"

                if (category == "events_mm_cont_01a") form_id = "653229740023d58936912cec"
                if (category == "events_mm_nett_07") form_id = "653226b00023d58936912ce1"
                if (category == "events_mm_cont_09a") form_id = "653229a60023d58936912ced"


                if (category == "events_mauriac_cont_30") form_id = "6544b99ab5314b324bc0cb63"
                if (category == "events_gdb_act_01") form_id = "6568d16cd1702e393743239d"





            }
*/

            if (id == "inbound-milk") return description_inbound_milk;

            if (form_id != "") {
                // console.log("ui-forms >>>> 2222222")
                let response = await this.getDataId("ui-forms", form_id, token)
                return response;
            }

            if (id != "") {

                //  console.log("ui-forms >>>> 1111111")
                let response = await this.getDataCode("ui-forms", id, filter, token)
                return response;
            }

            return null;

        },
        async getConfiguration(id, code, token) {
            let list_id = ""

            if (id == "events") {
                //default 64ca634fae9f4714d49d609f
                if (code == undefined) return null;
                if (code.toLowerCase() == 'enr_cont_01a') list_id = "64ca686bae9f4714d49d60af" //return list_events_enr_cont_01a;
                if (code.toLowerCase() == 'enr_cont_03') list_id = "64ca68b9ae9f4714d49d60b0" //return list_events_enr_cont_03;
                if (code.toLowerCase() == 'enr_cont_09a') list_id = "64ca6af5ae9f4714d49d60b9" //return list_events_enr_cont_09a;
                if (code.toLowerCase() == 'enr_cont_09c') list_id = "64ca6b36ae9f4714d49d60ba" // return list_events_enr_cont_09c;
                if (code.toLowerCase() == 'enr_cont_10a') list_id = "64ca6b76ae9f4714d49d60bb" //return list_events_enr_cont_10a;
                if (code.toLowerCase() == 'enr_cont_11c') list_id = "64ca6bd0ae9f4714d49d60bc" //return list_events_enr_cont_11c;
                if (code.toLowerCase() == 'enr_cont_23') list_id = "64ca690aae9f4714d49d60b1" // return list_events_enr_cont_23;
                if (code.toLowerCase() == 'enr_cont_25') list_id = "64ca694cae9f4714d49d60b2" //return list_events_enr_cont_25;
                if (code.toLowerCase() == 'enr_nett_4a') list_id = "64ca6983ae9f4714d49d60b3" // return list_events_enr_nett_4a;
                if (code.toLowerCase() == 'enr_nett_4b') list_id = "64ca69bdae9f4714d49d60b4" //return list_events_enr_nett_4b;
                if (code.toLowerCase() == 'enr_nett_07') list_id = "64ca6a05ae9f4714d49d60b5" //return list_events_enr_nett_07;
                if (code.toLowerCase() == 'enr_nett_08') list_id = "64ca6a44ae9f4714d49d60b6" //return list_events_enr_nett_08;
                if (code.toLowerCase() == 'enr_nett_09') list_id = "64ca6a7eae9f4714d49d60b7" //return list_events_enr_nett_09;
                if (code.toLowerCase() == 'enr_nett_12') list_id = "64ca6abdae9f4714d49d60b8" //return list_events_enr_nett_12;
                if (code.toLowerCase() == 'enr_cont_14') list_id = "64ca6c10ae9f4714d49d60bd" //return list_events_enr_cont_14;
                if (code.toLowerCase() == 'mm_nett_07') list_id = "653226260023d58936912cdf" // return list_events_enr_cont_23;
                if (code.toLowerCase() == 'mm_cont_09a') list_id = "653228bd0023d58936912cea" // return list_events_enr_cont_23;
                if (code.toLowerCase() == 'mm_cont_01a') list_id = "653228f60023d58936912ceb" // return list_events_enr_cont_23;
                //Reception Marchandise
                if (code.toLowerCase() == 'mauriac_cont_30') list_id = "6543819ab5314b324bc0cb0d" // return list_events_enr_cont_23;
                if (code.toLowerCase() == 'mauriac_cont_30b') list_id = "6543a501b5314b324bc0cb23" // return list_events_enr_cont_23;


                if (code.toLowerCase() == 'gdb_act_01') list_id = "6568cf1ed1702e3937432399" //return list_events_enr_cont_01a;


            }


            //control_suppliers   64ca5f2bae9f4714d49d6090


            if ((list_id != undefined) && (list_id != "")) {

                let response = await this.getDataId("ui-lists", list_id, token)
                return response;
            }
            if ((id != undefined) && (id != "")) {

                let response = await this.getDataCode("ui-lists", id, {}, token)
                return response;

            }
            return {}
        },
        async getConfigurationRemote(id, token) {
            if (token == undefined) return null;

            let url = "/api/b4food/configurations/" + id;

            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token,
                },
            });

            return response?.data
        },
        async getFilterBarDefinition(id, token, filter) {


            let q = "";
            if (filter != null) {
                let keys = Object.keys(filter)
                for (let i = 0; i < keys.length; i++) {
                    if (q != "") q = q + "&";
                    q = q + keys[i] + "=" + filter[keys[i]]
                }
            }
            let url = "/api/b4food/ui/views/" + id + "/filterBar?view=" + id;

            if (q != "") url = url + "&" + q


            // if (q != "") url = url + "?" + q;

            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token,
                },
            });

            //   console.log("getFilterBarDefinition getFilterBarDefinition getFilterBarDefinition getFilterBarDefinition getFilterBarDefinition")
            //    console.log("getFilterBarDefinition getFilterBarDefinition getFilterBarDefinition getFilterBarDefinition getFilterBarDefinition")
            //     console.log("getFilterBarDefinition getFilterBarDefinition getFilterBarDefinition getFilterBarDefinition getFilterBarDefinition")
            //     console.log(response?.data)



            if (response)
                return response?.data

            return [];


        },
        async getFilterTabDefinition(id, token, filter) {


            let q = "";
            if (filter != null) {
                let keys = Object.keys(filter)
                for (let i = 0; i < keys.length; i++) {
                    if (q != "") q = q + "&";
                    q = q + keys[i] + "=" + filter[keys[i]]
                }
            }
            let url = "/api/b4food/ui/views/" + id + "/filterTab?" + q;
            // if (q != "") url = url + "?" + q;

            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token,
                },
            });



            if (response)
                return response?.data

            return [];


        },
        async getDataCode(collection, id, filter, token) {

            if (token == undefined) return null;
            let url = "/api/b4food/" + collection + "/code/" + id;

            let q = "";
            if (filter != null) {
                let keys = Object.keys(filter)

                for (let i = 0; i < keys.length; i++) {

                    if ((filter[keys[i]] != undefined) && (filter[keys[i]] != "")) {
                        if (q != "") q = q + "&";
                        q = q + keys[i] + "=" + filter[keys[i]]
                    }

                }
            }
            if (q != "") url = url + "?" + q;


            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token,
                },
            });
            if (response)
                return response?.data

            return null;
        },
        async getFileBase64(file, token) {

            //     console.log("getFileBase64 - getFileBase64 - getFileBase64")

            //        console.log(file)

            let id = file.replace("https://file-storage-ten.vercel.app/b4food/files/", "")
            if (token == undefined) return null;

            let url = "/api/b4food/files/base64/" + id

            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token,
                },
            });
            if (response)
                return response?.data

            return null
        },
        async getDataId(collection, id, token, filter) {

            if (token == undefined) return null;
            let url = "/api/b4food/" + collection + "/" + id;




            let q = "";
            if (filter != null) {
                let keys = Object.keys(filter)

                for (let i = 0; i < keys.length; i++) {

                    if ((filter[keys[i]] != undefined) && (filter[keys[i]] != "")) {
                        if (q != "") q = q + "&";
                        q = q + keys[i] + "=" + filter[keys[i]]
                    }

                }
            }

            if (q != "") url = url + "?" + q;

            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token,
                },
            });
            if (response)
                return response?.data

            return null;
        },
        async generatePDF(id, token, filter) {



            // let url = "http://localhost:5514/generateFile/" + id;

            //  let url = "https://seashell-app-rrlf8.ondigitalocean.app/generateFile/" + id;

            // let url = "https://pdf.b4food.io/generateFile/" + id;


            let url = "http://localhost:5514/generateFile/" + id;
            let q = "";

            if (filter != null) {
                let keys = Object.keys(filter)

                for (let i = 0; i < keys.length; i++) {

                    if ((filter[keys[i]] != undefined) && (filter[keys[i]] != "")) {
                        if (q != "") q = q + "&";
                        q = q + keys[i] + "=" + filter[keys[i]]
                    }

                }
            }

            if (q != "") url = url + "?" + q;

            //  console.log("Generate PDF - Generate PDF")
            //   console.log(url)
            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token
                }
            });


            if (response)
                return response?.data

            return null;
        },
        async generateReport(collection, token, filter, body, mode = "json") {

            if (token == undefined) return null;

            let mime = "application/json"

            if (mode == "csv")
                mime = "application/csv"



            if (body == undefined) body = {}

            let q = "";

            if (filter != null) {
                let keys = Object.keys(filter)

                for (let i = 0; i < keys.length; i++) {

                    if ((filter[keys[i]] != undefined) && (filter[keys[i]] != "")) {
                        if (q != "") q = q + "&";
                        q = q + keys[i] + "=" + filter[keys[i]]
                    }

                }
            }




            let url = "/api/b4food/" + collection;
            if (q != "") url = url + "?" + q;

            //   console.log("GenerateReport---------------------------------------------")
            //   console.log(url)
            //   console.log("GenerateReport---------------------------------------------")


            let response = await axios.post(url, body, {
                headers: {
                    "x-access-token": token,
                    "accept": mime
                }
            });


            if (response)
                return response?.data

            return null;
        },

        async getData(collection, token, filter, mode = "json") {

            if (token == undefined) return null;

            let mime = "application/json"

            if (mode == "csv")
                mime = "application/csv"

            if (collection == "events") {
                if (filter['code']) filter['code'] = filter['code'].toLowerCase();
            }

            //if (collection == "inbound-items") {
            //    collection = "receipts"
            // }
            if (collection == "inbound-milk") {
                collection = "receipts"
            }

            let q = "";

            if (filter != null) {
                let keys = Object.keys(filter)

                for (let i = 0; i < keys.length; i++) {

                    if ((filter[keys[i]] != undefined) && (filter[keys[i]] != "")) {
                        if (q != "") q = q + "&";
                        q = q + keys[i] + "=" + filter[keys[i]]
                    }

                }
            }


            let url = "/api/b4food/" + collection;
            if (q != "") url = url + "?" + q;

            //console.log("GetData---------------------------------------------")
            //console.log(url)
            //console.log("GetData---------------------------------------------")


            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token,
                    "accept": mime
                }
            });

            if (response)
                return response?.data

            return null;
        },

        async ResetPassword(user) {

            let url = "/api/b4food/auth/reset-password";


            //console.log("GetData---------------------------------------------")
            //console.log(url)
            //console.log("GetData---------------------------------------------")


            let response = await axios.post(url, user, {
                headers: {

                    "accept": "application/json"
                }
            });


            if (response)
                return response?.data
            return null;
        },


        async ChangePassword(user) {

            let url = "/api/b4food/auth/change-password";

            //console.log("GetData---------------------------------------------")
            //console.log(url)
            //console.log("GetData---------------------------------------------")


            let response = await axios.post(url, user, { headers: { "accept": "application/json" } });


            if (response) {
                if (response?.data) return response?.data
                if (response?.error?.data) return response?.error?.data
            }
            return null

        },

        async ChangeUserPincode(user, token) {
            //  console.log("ChangeUserPincode---------------------------------------------")
            let url = "/api/b4food/users/" + user.id + "/pincode";
            let body =
            {
                "pincode": user.pincode
            }

            let response = await axios.patch(url, body, {
                headers: {

                    "accept": "application/json",
                    "x-access-token": token,
                }
            });

            if (response)
                return response?.data
            return null;
        },


        async ResetPassword2(user, token) {
            //   console.log("ResetPassword2---------------------------------------------")
            let url = "/api/b4food/auth/reset-password-2";

            //  console.log("GetData---------------------------------------------")
            //console.log(url)
            //console.log("GetData---------------------------------------------")

            let response = await axios.post(url, user, {
                headers: {

                    "accept": "application/json",
                    "x-access-token": token,
                }
            });


            if (response)
                return response?.data
            return null;
        },




        async getLoginName(token) {
            //   console.log("getLoginName---------------------------------------------")
            let url = "/api/b4food/auth/token/" + token;

            //    console.log("GetData---------------------------------------------")
            //console.log(url)
            //console.log("GetData---------------------------------------------")

            let response = await axios.get(url, {
                headers: {

                    "accept": "application/json"
                }
            });


            if (response)
                return response?.data
            return null;
        },

        async ActivateUser(user, token) {
            //   console.log("ActivateUser---------------------------------------------")
            let url = "/api/b4food/auth/user";

            //    console.log("GetData---------------------------------------------")
            //console.log(url)
            //console.log("GetData---------------------------------------------")

            let response = await axios.post(url, user, {
                headers: {

                    "accept": "application/json",
                    "x-access-token": token,
                }
            });


            if (response)
                return response?.data
            return null;
        },



        async sendInvitationMail(item, token) {

            let url = "/api/b4food/auth/invitation";
            if (token == undefined) return null;

            let response = await axios.post(url, item, {
                headers: {
                    "accept": "application/json",
                    "x-access-token": token,
                }
            });

            if (response) {
                if (response?.data) return response?.data
                if (response?.error?.data) return response?.error?.data
            }
            return null
        },




        async LogIn(user) {

            let url = "/api/b4food/auth/login";


            //console.log("GetData---------------------------------------------")
            //console.log(url)
            //console.log("GetData---------------------------------------------")


            let response = await axios.post(url, user, { headers: { "accept": "application/json" } });


            if (response) {
                if (response?.data) return response?.data
                if (response?.error?.data) return response?.error?.data
            }
            return null

        },



        async getMenu() {
            /*
                        if (token == undefined) return null;
            
                        let url = "/api/b4food/menus";
            
                        let response = await axios.get(url, {
                            headers: {
                                "x-access-token": token,
                            },
                        });
            
                        return response?.data*/
            return null;
        },
        async getRecord(collection, record, token) {

            if (token == undefined) return null;
            if (record == undefined) return {};
            if (record == 'new') {

                return {};
            }

            let url = "/api/b4food/" + collection + "/" + record;

            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token,
                },
            });

            return response?.data
        },
        async updateRecord(collection, record, token, data) {

            if (token == undefined) return null;

            let url = "/api/b4food/" + collection + "/" + record;

            let response = await axios.patch(url, data, {
                headers: {
                    "x-access-token": token,
                },
            });
            if (response == null) {

                return null;
            }
            if (response.data == null) {

                return null;
            }
            return response?.data
        },
        async deleteRecord(collection, token, id) {


            if (token == undefined) return null;
            let url = "/api/b4food/" + collection + "/" + id;

            let response = await axios.delete(url, {
                headers: {
                    "x-access-token": token,
                },
            });
            if (response == undefined) return null;
            if (response.data == undefined) return null;
            return response?.data
        },
        async addRecord(collection, token, data) {

            if (token == undefined) return null;

            let url = "/api/b4food/" + collection;

            let response = await axios.post(url, data, {
                headers: {
                    "x-access-token": token,
                },
            });

            return response?.data
        },
        async duplicateRecord(collection, token, id) {

            if (token == undefined) return null;

            let data = {
                id: id
            }

            let url = "/api/b4food/" + collection + "/duplicate";

            let response = await axios.post(url, data, {
                headers: {
                    "x-access-token": token,
                },
            });

            return response?.data
        },
        async getScanPoint(token) {

            if (token == undefined) return null;

            let url = "/api/b4food/scan-logs/points";

            let response = await axios.get(url, {
                headers: {
                    "x-access-token": token,
                },
            });

            return response?.data
        },
        UpdateLcaGraph: async function (value, token) {
            //let response = 

            await axios.patch("/api/b4food/lca-graph/" + value.id, value, {
                headers: {
                    "x-access-token": token,
                    "content-type": "application/json"
                },
            });


        },
        UpdateWorkflow: async function (value, token) {
            //let response = 

            if (value.id == undefined)
                value.id = value._id
            //  console.log("patch " + "/api/b4food/workflows/" + value.id)
            let response = await axios.patch("/api/b4food/workflows/" + value.id, value, {
                headers: {
                    "x-access-token": token,
                    "content-type": "application/json"
                },
            });

            // console.log(response.data)
            return response.data

        }


    }
</script>