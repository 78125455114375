<template>
    <div class="relative  mt-2 mb-2 w-full ">

    
        <nav class=" border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800 justify-end">


            <div class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2" v-if="!isLoading">
                <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0 justify-end w-full  ">
                    <li>
                        <a href="#" @click="onDownload"
                            class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 text-sm font-bold"
                            aria-current="page"><i class="fa-solid fa-download mr-1"></i> Télécharger</a>
                    </li>
                    <li>
                        <a href="#" @click="printPDF"
                            class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 text-sm font-bold">
                            <i class="fa-solid fa-print mr-1"></i> Imprimer</a>
                    </li>
                    <li>
                        <a href="#"
                            class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 text-sm font-bold">
                            <i class="fa-solid fa-share mr-1"></i> Renvoyer</a>
                    </li>
                </ul>
            </div>
        </nav>

       
        <div class="min-h-96">
            <vue-pdf-embed ref="pdfRef3" class="w-full" :source="source2" @loaded="handleDocumentLoad"
                @rendered="handleDocumentRender" />
        </div>

    </div>
</template>


<script>
    // import service from "@/services/FullService.vue"
    import { mapGetters } from "vuex";
    import VuePdfEmbed from 'vue-pdf-embed'


    export default {
        name: 'ContentPDF',
        components: {

            VuePdfEmbed,
        },
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,
            value: Object,
            record: Object,
            isActive: Boolean,
            currentTab: Number,
            indexTab: Number,

            config: Object
        },
        computed:
        {
            ...mapGetters({ user: "StateUser" }),
            mapRef: function (a) {
                if (a == undefined) return null;
                return a;
            }
        },

        data() {
            return {
                source2: "", source3: "",
                output: "",

                isLoading: true,
                page: null,
                pageCount: null,
                c: 0
            }
        },
        watch: {
            isActive: async function (val) {
                console.log("isActive", val)
                if (val == true) {
                    this.source2 = "https://files.b4food.io/b4food/files/" + this.value

                }

                else
                    this.source2 = ""
            },
            currentTab: async function (val) {
                console.log("currentTab", val)
            },

            indexTab: async function (val) {
                console.log("indexTab", val)
            },


            value: async function (val) {
                this.output = val;


                if (this.value != undefined) {
                    // let pdf = await service.getDataId("files/base64", this.findFileId(this.value.url), this.user?.token, { fields: 'id,name, data' })


                    //   let pdf = {data: null} 
                    this.c++
                    //this.source2 = "https://files.b4food.io/b4food/files/" + this.value
                    //    this.source2 = pdf?.data;

                }
            }
        },

        async mounted() {

            this.output = this.value;
            if (this.value != undefined) {
                //  let pdf = await service.getDataId("files/base64", this.value, this.user?.token, { fields: 'id,name, data' })


                this.source3 = "https://files.b4food.io/b4food/files/" + this.value

            }






            /*
                        this.$nextTick(function () {
                            window.scrollTo(0, 0)
                        }); */
        },
        methods: {
            handleDocumentRender() {
                console.log("handleDocumentRender")
                this.isLoading = false
            },
            handleDocumentLoad(numPages) {
                console.log("handleDocumentLoad")

                this.pageCount = numPages.numPages
            },
            onDownload: function () {
                // this.source2 = "https://files.b4food.io/b4food/files/" + this.value
                if (this.$refs['pdfRef3']) { this.$refs['pdfRef3'].download("rest.pdf") }
                //    this.downloadPDF(this.record.data, "FT-" + this.record.product.reference + "-" + this.profile + ".pdf")
            },
            printPDF: function () {
                if (this.$refs['pdfRef3']) { this.$refs['pdfRef3'].print() }
            },
            downloadPDF: function (base64, filename) {

                // Create an anchor element

                var link = document.createElement("a");
                link.href = base64;
                link.download = filename;
                document.body.appendChild(link);
                // Click the link
                link.click();
                // Remove the link
                document.body.removeChild(link);
            },

            OnView: function (v) {
                this.viewBy = v


                this.$nextTick(() => {
                    if (v == 2) {

                        this.$refs.pdfRef1.render()
                        this.$refs.pdfRef2.render()
                    }
                });


            },


            getData() {


            }
        }
    }
</script>


<style>
    .vue-pdf-embed>div {
        margin-bottom: 8px;
        box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    }

    .vue-pdf-embed>div>div>canvas {
        min-height: 400px;
        width: 700px
    }
</style>