<template>
  <div class="  bg-gray-50 pr-8  ">
    <div class="relative  mt-2  pr-4">
      <div class=" w-full   pb-2 flex items-center   ">
        <h2
          class="  pl-2 text-left w-full   font-bold text-gray-900    dark:text-white flex items-center justify-start w-full "
        >
          <div
            v-if="isBack"
            class="pr-3 "
          >
            <div
              class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              @click="onBack"
            >
              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
                  
          <div class="w-full text-3xl   ">
            {{ config?.title?.FR }}
          </div> 
             
          <h4 class="flex   justify-end  ml-4 text-right   font-semibold text-gray-500    items-center">
            <div class=" w-48 text-gray-600  bg-gray-100   font-semibold  text-center  rounded-sm text-sm">
              {{ config?.subtitle?.FR }}
            </div>
          </h4>
        </h2>
      </div>
         
   
      <B4Table
        :page_id="$route.params.id"
        :current-tab="currentTab"
        :is-loading="isLoading"
        :filters="filters"
        :current_page="current_page"
        :is-loading2="isLoading2"
        :is-loading3="isLoading3"
        :limit="limit"
        :offset="offset"
        :config="config"
        :records="records"
        :filter-list="filterList"
        :results="results"
        @row-click="onRowClick"
        @add="onAddClick"
        @duplicate="onDuplicateClick"
        @query="onQuery"
        @export="onExportClick"
        @refresh="onRefreshClick"
        @refresh-tab="onRefreshTab"
        @select-page="onSelectPage"
        @refresh-page="onRefreshPage"
        @refresh-table="onRefreshTable"
        @search="onSearch"
        @download="onDownloadFile"
        @select-tab="onSelectTab"
      />
    </div>
  </div>
  <SelectProductType
    @close="OnSelectProductTypeClose"
    @select="OnSelectProductTypeSelected"
  />
  <SelectProduct
    :records="productTypes"
    @close="OnSelectProductClose"
    @select="OnSelectProductSelected"
  />


  

 
 
</template>
<script>
    const MAX_ITEMS = 50;
    import { Modal } from 'flowbite';
    import B4Table from '@/components/TableComponent.vue'
    import service from "@/services/FullService.vue"
    import SelectProductType from '@/components/modals/SelectProductType.vue'
    import SelectProduct from '@/components/modals/SelectProduct.vue'


    import { mapGetters } from "vuex";
    export default {
        name: 'TableView',
        components: {
            B4Table, SelectProductType, SelectProduct
        },
        props : {
            refresh: String
        },
         data() {
            return {
                currentTab: 0,
                isLoading: false,
                isLoading2: false,
                isLoading3: false,
                config: {},
                globalQuery: '',
                category: null,
                productTypes: [],
                records: [],
                current_page: 1,
                lastQuery: "",
                offset: 0,
                filters: {},
                limit: MAX_ITEMS,
                lg: "FR",
                title: "",
                filterList: [],

                people: [],
                results: {
                    latest: [],
                    matching: [],
                    suggestions: [],
                    buttons: []

                },

                selectProductTypeModal: null,
                selectProductModal: null,
                button: ""
            }
        },
        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
            isBack: function () {
                return (this.$route?.query?.back) || (this.config?.options?.displayBack == true)
            },

            isDeveloper: function () {
                return this.user?.isDeveloper;
            },
        },
       
        watch:
        {
            'refresh': async function( )
            {
             //   console.log("Change ROUTE =", this.$route.params.id)
                let filters = {}
                this.category = "";
                this.globalQuery = "";
                //filters['category'] = config.filters;
                this.current_page = 1;
                let val  = this.$route.params.id
                this.reloadPage(val, 0, MAX_ITEMS, filters, 1, this.current_page);
                window.scrollTo(0, 0);
                await this.loadLatestSearches()
            },
            '$route.params.id': async function (val) {
           
               // console.log("Change ROUTE =", this.$route.params.id)
                let filters = {}
                this.category = "";
                this.globalQuery = "";
                //filters['category'] = config.filters;
                this.current_page = 1;
           
                this.reloadPage(val, 0, MAX_ITEMS, filters, 1, this.current_page);
                window.scrollTo(0, 0);
                await this.loadLatestSearches()
            },
            config: function (val) {
                if (val) {
                    if (val.title) this.title = val.title[this.lg];
                    if (val.button) this.button = val.button[this.lg];
                }

            },
            'user.tenant': async function () {
                console.log("Changement de Tenant / Changement de Tenant")
                console.log("Changement de Tenant / Changement de Tenant")
                console.log("Changement de Tenant / Changement de Tenant")
                console.log("Changement de Tenant / Changement de Tenant")
                console.log("Changement de Tenant / Changement de Tenant")
                this.isLoading = true;
                let filters = {}
                this.category = "";
                this.globalQuery = "";
           
                this.reloadPage(this.$route.params.id, 0, MAX_ITEMS, filters);
                window.scrollTo(0, 0);

            }
        },
        async mounted() {

            let productTypes = [];

            await this.loadLatestSearches()

            this.results.suggestions = [
                {
                    id: 1,
                    name: "Tablettes"
                },
                {
                    id: 2,
                    name: "Smartphones"
                },
                {
                    id: 3,
                    name: "Pas encore activée"
                },
                {
                    id: 4,
                    name: "Suspendu"
                },
                {
                    id: 5,
                    name: "Android"
                }

            ]

            if (this.$route.params.id == 'products') {


                //     let collection = "raw-products"
                let collection = "data-sources/668cf29b9535b23c096b640b/records"

                let records = await service.getData(collection, this.user?.token, Object.assign({}, { limit: 50, offset: 0 }, {}))

                for (let i = 0; i < records.records.length; i++) {


                    let url = records.records[i].image_id;

                    //    if (records.records[i].image_front_product != undefined)
                    //        if (records.records[i].image_front_product.url != undefined)
                    //            url = records.records[i].image_id

                    productTypes.push(
                        {
                            id: records.records[i]._id,
                            type: records.records[i].type,
                            url: url,
                            category: records.records[i].category,
                            name: records.records[i].name
                        }
                    )
                }

                this.productTypes = productTypes

            }


            const modalEl = document.getElementById('select-product-type-modal');
            this.selectProductTypeModal = new Modal(modalEl, {
                placement: 'center'
            });

            const modalEl2 = document.getElementById('select-product-modal');
            this.selectProductModal = new Modal(modalEl2, {
                placement: 'center'
            });

            let offset = 0;

            if (this.$route.query?.page != undefined) {
                offset = (this.$route?.query?.page - 1) * MAX_ITEMS
            }

            let filter = {}
            let keys = Object.keys(this.$route.query)
            for (let i = 0; i < keys.length; i++) {

                if (keys[i] == "category") {
                    this.category = this.$route.query[keys[i]]
                }
                else if (keys[i] == "tab") {
                    this.currentTabs = 0;
                    // let q = this.$route.query.tab.toLowerCase()
                    //     let index = this.config.tabs.findIndex(function (a) { return a.id.toLowerCase() == q })

                    //                     if (index == -1)  {   this.currentTab = 0 }
                    //                   else  this.currentTab = index
                }
                else {
                    if ((keys[i] != "page") && (keys[i] != "sort")) {
                        filter[keys[i]] = this.$route.query[keys[i]]
                    }
                }


            }



            let page = this.current_page
            if (this.$route.query.page != undefined) {
                page = parseInt(this.$route.query.page)
                this.current_page = page
                if (this.current_page < 1) this.current_page = 1;
            }

            this.filters = filter;
      
            this.reloadPage(this.$route.params.id, offset, MAX_ITEMS, filter, 1, page);


        },
        methods: {

            onBack: function () {

                let url = this.$route.query.back;
                // if (this.config && this.config.options && this.config.options.displayBack != false) {
                //     url = this.config.options.urlBack;
                // }
                //   window.location = url
                this.$router.push(url)
            },


            onChangeTitle: function () {
                console.log("onChangeTitle - todo")
            },
            onChangeSubTitle: function () {
                console.log("onChangeSubTitle - todo")
            },

            loadLatestSearches: async function () {
                let filter = {}
                let records = await service.getData("searches/" + this.$route.params.id, this.user?.token, Object.assign({}, { limit: 5, offset: 0 }, filter))
                let result = []
                if (records?.records.length > 0) {
                    for (let i = 0; i < records.records[0].queries.length; i++) {
                        result.push({ id: records.records[0]._id, name: records.records[0].queries[i] })
                    }
                 //   console.log(result)
                    this.results.latest = result;

                }
                else
                    this.results.latest = []

            },
            loadSuggestionsSearches: async function () {

            },
            onSearch: async function (search) {

                let filters = this.filters;
                delete filters['q']

                let filter = { ...search.filter, ...filters };

                filter.autocomplete = 1;
                let records = await service.getData("data-sources/" + this.config.source_id + "/records", this.user?.token, Object.assign({}, { limit: 5, offset: 0 }, filter));

                if ((filter?.q != undefined) && (filter?.q != "")) {
                    let body =
                    {
                        id: this.$route.params.id,
                        category: null,
                        query: filter?.q
                    }
                    await service.addRecord("searches", this.user?.token, body)
                }






                let result = []
                for (let i = 0; i < records.records.length; i++) {
                    result.push({ id: records.records[i]._id, name: records.records[i].name, reference: records.records[i].reference })
                }
                this.results.matching = result;

            },
            onSelectTab: function (tab) {

                let index = this.config.tabs.findIndex(function (a) { return (a.id == tab) })


                this.category = this.config.tabs[index]?.filter?.category
                this.currentTab = index;
                this.globalQuery = ""
                this.current_page = 1;
                let filter = {}; // this.config.tabs[this.currentTab].filter;
                this.offset = 0;
                this.limit = MAX_ITEMS;

                //  this.$router.push({ path: this.$route.fullPath, query: { tab: tab, page: 1 } });

                filter['tab'] = tab

    
                this.reloadPage(this.$route.params.id, this.offset, this.limit, filter, 3, this.current_page);

            },
            onQuery: async function (query) {
                if (this.lastQuery == query)
                    return this.filterList;


                let id = this.$route.params.id;
                let filter = { q: query }
                let records = await service.getData(id, this.user?.token, Object.assign({}, { limit: 5, offset: 0 }, filter))

                let result = []

                if (records == undefined) return [];
                if (records.records == undefined) return []
                for (let i = 0; i < records.records.length; i++) {
                    result.push({ name: records.records[i].name, reference: records.records[i].reference })
                }

                this.lastQuery = query;
                this.globalQuery = query;
                this.filterList = result
            },

            onDownloadFile: async function (record) {

                let base64 = await service.getFileBase64(record.file, this.user?.token)

                // Create an anchor element
                var link = document.createElement("a");
                link.href = base64.data;
                link.download = record.name;
                document.body.appendChild(link);
                // Click the link
                link.click();
                // Remove the link
                document.body.removeChild(link);
            },

            async onExportClick() {

                let filter = {}
                let csv = await service.getData('products', this.user?.token, Object.assign({}, { limit: 3000, offset: 0 }, filter), "csv")

                var link = document.createElement("a");
                link.href = "data:text/csv;charset=utf-8," + escape(csv);
                link.download = "export.csv";
                document.body.appendChild(link);
                // Click the link
                link.click();
                // Remove the link
                document.body.removeChild(link);


            },
            displayTitle() {
                if (this.user == undefined) return "";

                let lg = this.user.language;

                if (lg == undefined) lg = "FR"

                if (this.config == undefined) return "";
                if (this.config.title == undefined) return "";
                if (this.config.title[lg] == undefined) return "";
                return this.config.title[lg];
            },
            displaySubTitle() {
                if (this.user == undefined) return "";

                let lg = this.user.language;

                if (lg == undefined) lg = "EN"

                if (this.config == undefined) return "";
                if (this.config.title == undefined) return "";
                if (this.config.title[lg] == undefined) return "";
                return this.config.title[lg];
            },
            async reloadPage(id, offset, limit, filter, level, page) {
 
                if (level == null) level = 1
                if (this.user == undefined) {
                    //                    this.$router.push("/login");
                    window.location = "/login"
                    return;
                }

                this.current_page = page;

                let loffset = parseInt((page - 1) * limit);

                if (loffset == undefined) loffset = 0

                this.limit = limit;

                if (level == 1) this.isLoading = true;
                if (level == 2) this.isLoading2 = true;
                if (level == 3) this.isLoading3 = true;

                let code = null;

                if (this.$route.query.code != undefined)
                    code = this.$route.query.code;
              //  console.log("TableView " + code)
                let config = await service.getConfiguration(id, code, this.user?.token)

                if (config == undefined) {

                    return;
                }

                //TODO Remove this, tabs must be delivered via Backoffice


                if (config.tabs == undefined) {
                    let t = await service.getFilterTabDefinition(this.$route.params.id, this.user?.token, { template: this.$route.params.id })
                    {
                        //TODO Check how to remove this
                        if ((this.$route.params.id == 'suppliers') || (this.$route.params.id == 'products') || (this.$route.params.id == 'raw-products') || (this.$route.params.id == 'suppliers-product') || (this.$route.params.id == 'manufacturing-orders') || (this.$route.params.id == 'data-errors')) {
                            if (this.$route.query.tab == undefined)
                                if (t.records && t.records[0]) filter.category = t.records[0].id
                        }
                        if (t) {
                            config.tabs = t.records;

                        }
                    }
                }



                if (config?.search?.buttons != undefined) {

                    if (filter.category != undefined) {
                        let buttons = config.search.buttons[filter.category]
                        if (buttons != undefined) {
                            this.results.buttons = buttons
                        }
                        else {
                            this.results.buttons = []
                        }
                    }
                    else {
                        let buttons = config.search.buttons['*']
                        if (buttons != undefined) {
                            this.results.buttons = buttons
                        }
                        else {
                            this.results.buttons = []
                        }
                    }


                }
                else {
                    this.results.buttons = []
                }

                this.filter = filter;

                let filterBarFilter = {}
                if (this.$route.query.category != undefined) {
                    filterBarFilter.category = this.$route.query.category
                }


                let d = await service.getFilterBarDefinition(this.$route.params.id, this.user?.token, filterBarFilter)

                config.secondBar = d.records;

                for (let i = 0; i < config.secondBar.length; i++) {
                    if (config.secondBar[i].data == "supplier_id") {
                        if (filter["supplier_id"] != undefined) {

                            config.secondBar[i].selected = filter["supplier_id"]

                            if (filter["supplier_id"] == "*") {
                                delete filter["supplier_id"]

                            }


                        }

                        else config.secondBar[i].selected = "*"


                    }


                    if (config.secondBar[i].data == "item_organic") {
                        if (filter["item_organic"] != undefined) {

                            config.secondBar[i].selected = filter["item_organic"]

                            if (filter["item_organic"] == "*") {
                                delete filter["item_organic"]

                            }


                        }

                        else config.secondBar[i].selected = "*"


                    }


                    if (config.secondBar[i].data == "entity_id") {
                        if (filter["entity_id"] != undefined) {

                            config.secondBar[i].selected = filter["entity_id"]

                            if (filter["entity_id"] == "*") {
                                delete filter["entity_id"]

                            }


                        }

                        else config.secondBar[i].selected = "*"


                    }

                    if (config.secondBar[i].data == "location_id") {
                        if (filter["location_id"] != undefined) {

                            config.secondBar[i].selected = filter["location_id"]

                            if (filter["location_id"] == "*") {
                                delete filter["location_id"]

                            }


                        }

                        else config.secondBar[i].selected = "*"


                    }
                    if (config.secondBar[i].data == "supplier_type") {
                        if (filter["supplier_type"] != undefined) {

                            config.secondBar[i].selected = filter["supplier_type"]

                            if (filter["supplier_type"] == "*") {
                                delete filter["supplier_type"]

                            }


                        }
                        else config.secondBar[i].selected = "*"


                    }








                }



                if (this.$route.query.code) {
                    filter['code'] = 'events_' + this.$route.query.code
                }

                if (this.$route.query.type) {
                    filter['type'] = this.$route.query.type
                }

                if (this.$route.query.tags) {
                    filter['tags'] = this.$route.query.tags
                }

                if (config.tabs != undefined) {
                    if (config.tabs.length > 0) {
                        if (this.currentTab == undefined) this.currentTab = 0;
                        filter = { ...filter, ...config.tabs[this.currentTab].filter }
                    }







                    /*
                                        if (this.$route?.query?.tab == undefined) {
                    
                                            if (this.currentTab == undefined ) this.currentTab = 0;
                                            if (config.tabs.length > 0)
                                                filter = { ...filter, ...config.tabs[this.currentTab].filter }
                                            console.log("-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*")
                                            console.log("this.currentTab =",this.currentTab)
                                            console.log("=>", JSON.stringify(filter))
                                        }
                    
                                        else {
                                            let q = this.$route.query.tab.toLowerCase()
                                            let index = config.tabs.findIndex(function (a) { return a.id.toLowerCase() == q })
                    
                                            if (index == -1)  { index = 0; this.currentTab = index; }
                                            filter = { ...filter, ...config.tabs[index].filter }
                    
                                            console.log("!*!*!*!*!*!*!*!*!*!*!*:*!*:*:*-*-*-*-*-*-*-*")
                                            console.log("this.currentTab =",this.currentTab)
                                            console.log("=>", JSON.stringify(filter))
                    
                    
                                        }
                                        */
                }


                this.config = config;



                // this.records = await service.getData(id, this.user?.token, Object.assign({}, { limit: limit, offset: offset }, filter))



                let query = Object.assign({}, filter)



                if (query == undefined) query = {}


                query['page'] = page
 
                this.$router.replace({ path: this.$route.path, query: query })

                delete filter['tab']

                delete filter['page']



                this.records = await service.getData("data-sources/" + config.source_id + '/records', this.user?.token, Object.assign({}, { limit: limit, offset: loffset }, filter))


                if (level == 1) this.isLoading = false;
                if (level == 2) this.isLoading2 = false;
                if (level == 3) this.isLoading3 = false;
                this.filters = filter

                // Devrait être charger à l'ouverture popup
                await this.loadLatestSearches()

            },
            OnSelectProductTypeSelected: function (selection) {

                let template = "";

                //TODO
                if (this.$route.params.id == 'products') {

                    template = selection.item;

                }
                if (this.$route.params.id == 'raw-products') {

                    template = selection.product;
                }

                if (this.$route.params.id == 'product-suppliers') {

                    template = selection.product;
                }

                this.selectProductTypeModal.hide();

                let root = "/admin/views"
                if (this.config?.options?.url != undefined) {
                    root = this.config?.options?.url

                    if (root.indexOf("?") == -1)
                        root += "?back=" + this.$route.params.id
                    else
                        root += "&back=" + this.$route.params.id


                }

                let url = root + "/" + this.$route.params.id + "/new?template=" + template
                //this.$router.push(url)
                window.location = url

            },

            OnSelectProductSelected: function (/*item*/) {


//console.log(item)

                let template = "";

                //TODO

/*
                if (this.$route.params.id == 'products') {
                    if (item == "yoghurt") template = "product-yoghurt";
                    if (item == "cheese") template = "product-cheese";
                    if (item == "milk") template = "product-milk";
                    if (item == "cream") template = "product-cream";
                    if (item == "wine") template = "product-wine";
                    if (item == "olive-oil") template = "product-olive-oil";
                    if (item == "alcoholic-beverages") template = "product-alcoholic-beverage";
                    if (item == "none-alcoholic-beverages") template = "product-none-alcoholic-beverages";
                    template = item.category.replace("-raw", "")
                }
                if (this.$route.params.id == 'raw-products') {
                    if (item == "yoghurt") template = "product-yoghurt-raw";
                    if (item == "cheese") template = "product-cheese-raw";
                    if (item == "milk") template = "product-milk-raw";
                    if (item == "cream") template = "product-cream-raw";
                    if (item == "wine") template = "product-wine";
                    if (item == "olive-oil") template = "product-olive-oil";
                    if (item == "alcoholic-beverages") template = "product-alcoholic-beverage";
                    if (item == "alcoholic-none-beverages") template = "product-none-alcoholic-beverage";
                }*/

                this.selectProductModal.hide();

                let root = "/admin/views"
                if (this.config.options.url != undefined) {
                    root = this.config.options.url

                }
                let url = root + "/" + this.$route.params.id + "/new?template=" + template


                if (url.indexOf("?") == -1)
                    url += "?back=" + this.$route.params.id
                else
                    url += "&back=" + this.$route.params.id


                this.$router.push(url)
                //window.location = url
            },
            OnSelectProductClose: function () {
                this.selectProductModal.hide();
            },
            OnSelectProductTypeClose: function () {
                this.selectProductTypeModal.hide();
            },
            onSelectPage: function (page) {

                this.current_page = page;
            },
            onRefreshTab: function (item) {


                if (item.page == undefined) item.page = 1

                if (item?.filter?.category != undefined)
                    this.$router.replace({ path: this.$route.path, query: { tab: item.filter.category } })

        
                this.reloadPage(this.$route.params.id, item.offset, item.limit, item.filter, 2, item.page);
            },
            onRefreshClick: function (item) {
                if (item.page == undefined) item.page = 1
                // this.$router.push({ path: this.$route.path, query: { page: this.current_page, tab: this.current_tab } });

                let prefix = ""
                if (item.order == "up") prefix = "-"
                let filter = item.filter;
                if ((item.sort != undefined) && (item.sort != ""))
                    filter.sort = prefix + item.sort
        
                this.reloadPage(this.$route.params.id, (item.offset), item.limit, filter, 3, item.page);
            },
            onRefreshPage: function (item) {
                if (item.page == undefined) item.page = 1


                let prefix = ""
                if (item.order == "up") prefix = "-"

                let filter = item.filter;
                if ((item.sort != undefined) && (item.sort != ""))
                    filter.sort = prefix + item.sort

 
                this.reloadPage(this.$route.params.id, (item.offset - 1), item.limit, filter, 1, item.page);
            },

            onRefreshTable: function (item) {

                item.page = 1
                let prefix = ""
                if (item.order == "up") prefix = "-"
                let filter = item.filter;

                if ((item.sort != undefined) && (item.sort != ""))
                    filter.sort = prefix + item.sort


                for (let i = 0; i < this.config.secondBar.length; i++) {

 
                
                    if (this.config.secondBar[i].data == "supplier_id") {
                       
                        this.config.secondBar[i].selected = item.value
                        if (this.config.secondBar[i].selected == undefined)
                            this.config.secondBar[i].selected = "*"
                        if (this.config.secondBar[i].selected == "")
                            this.config.secondBar[i].selected = "*"
                    }


                    if (this.config.secondBar[i].data == "entity_id") {


                  


                        this.config.secondBar[i].selected = item.value
                        if (this.config.secondBar[i].selected == undefined)
                            this.config.secondBar[i].selected = "*"
                        if (this.config.secondBar[i].selected == "")
                            this.config.secondBar[i].selected = "*"
                    }



                    if (this.config.secondBar[i].data == "supplier_type") {
                        this.config.secondBar[i].selected = item.value
                        if (this.config.secondBar[i].selected == undefined)
                            this.config.secondBar[i].selected = "*"
                        if (this.config.secondBar[i].selected == "")
                            this.config.secondBar[i].selected = "*"
                    }


                    if (this.config.secondBar[i].data == "item_organic") {
                        this.config.secondBar[i].selected = item.value
                        if (this.config.secondBar[i].selected == undefined)
                            this.config.secondBar[i].selected = "*"
                        if (this.config.secondBar[i].selected == "")
                            this.config.secondBar[i].selected = "*"
                    }


                }


                //      this.reloadPage(this.$route.params.id, (item.offset - 1), item.limit, filter, 3);
 
                this.reloadPage(this.$route.params.id, item.offset, item.limit, filter, 3, item.page);
            },


            onDuplicateClick: async function (id) {



                if ((this.$route.params.id == 'products') || ((this.$route.params.id == 'technical-sheet-templates'))) {

                    //  this.records = await service.getData(id, this.user?.token, Object.assign({}, { limit: limit, offset: offset }, filter))

                    let record = await service.duplicateRecord(this.$route.params.id, this.user?.token, id)

                    let root = "/admin/views"

                    let url = root + "/" + this.$route.params.id + "/" + record.insertedId
                    if (url.indexOf("?") == -1)
                        url += "?back=" + this.$router.currentRoute?._rawValue?.fullPath
                    else
                        url += "&back=" + this.$router.currentRoute?._rawValue?.fullPath

                    window.location = url

                }



            },


            onAddClick: function () {

                if (this.$route.params.id == "batches") {
                    let root = "/admin/wizard"
                    let url = root + "/" + this.$route.params.id + "/new"
                    this.$router.push(url)

                    return;
                }

          /*      if (this.$route.params.id == "actors") {
                    let root = "/admin/wizard"
                    let url = root + "/" + this.$route.params.id + "/new"
                    this.$router.push(url)

                    return;
                }*/

                if (this.$route.params.id == "manufacturing-orders") {
                    let root = "/admin/wizard"
                    let url = root + "/" + this.$route.params.id + "/new"
                    this.$router.push(url)

                    return;
                }
                if (this.$route.params.id == "inbound-shipments") {
                    let root = "/admin/wizard"
                    let url = root + "/" + this.$route.params.id + "/new"
                    this.$router.push(url)

                    return;
                }

                //TODO
                let template = null;

                if (this.$route.params.id == 'product-suppliers') {


                    if (this.config.tabs != undefined) {

                        template = this.config.tabs[this.currentTab].id

                    }


                }



                if (this.$route.params.id == 'products') {


                    this.selectProductModal.show();
                    return;
                }

                if (this.$route.params.id == 'raw-products') {
                    this.selectProductTypeModal.show();
                    return;
                }
                let root = "/admin/views"
                if (this.config.options.url != undefined) {
                    root = this.config.options.url
                }

                let url = root + "/" + this.$route.params.id + "/new"

                //TODO Should be more precize for Ingredients

                if (template != undefined)
                    url = url + "?template=product-" + template;
                //  this.$router.push(url)
                window.location = url
            },
            onRowClick: function (item) {

                if (this.config)
                    if (this.config.options)
                        if (this.config.options.rowClick == false) {
                            return;
                        }

                let root = "/admin/views"
                if (this.config.options.url != undefined) {
                    root = this.config.options.url
                }
                let url = root + "/" + this.$route.params.id + "/" + item.record
                if (url.indexOf("?") == -1)
                    url += "?back=" + this.$router.currentRoute?._rawValue?.fullPath
                else
                    url += "&back=" + this.$router.currentRoute?._rawValue?.fullPath

                window.location = url
            }
        }
    }
</script>