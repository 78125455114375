<template>
  <div id="show-wild-game-modal" tabindex="-1" aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
    <div class="relative w-full  max-w-3xl p-4   ">
      <!-- Modal content -->
      <div class=" relative pt-4 pb-2 bg-gray-200 rounded-lg shadow dark:bg-gray-800  flex flex-col  items-stretch   "
        style="min-height:600px">
        <!-- Modal header -->
        <div
          class="  px-4 flex items-center justify-between pb-4 mb-4 border-b border-gray-200 rounded-t sm:mb-5 dark:border-gray-700">
          <h3 class="font-semibold text-gray-900 text-lg dark:text-white">
            {{ labels['TITLE_PHOTOS'][lg] }}
          </h3>
          <button type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex dark:hover:bg-gray-600 dark:hover:text-white"
            @click=" onClose()">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>


        <div class=" items-stretch bg-white rid gap-4 mb-4 sm:grid-cols-1 sm:gap-6 sm:mb-5 p-4 h-full min-h-96">


          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

            <div class="cursor-pointer" v-for="photo in input.veto_photos" :key="photo.id" @click="openPhoto(photo)">
              <img class="h-auto max-w-full rounded-lg" :src="`https://files.b4food.io/b4food/images/${photo}`" alt="">
            </div>
            <div class="cursor-pointer" v-for="photo in input.nemrod_photos" :key="photo.id" @click="openPhoto(photo)">
              <img class="h-auto max-w-full rounded-lg" :src="`https://files.b4food.io/b4food/images/${photo}`" alt="">
            </div>
          </div>


        </div>


        <div class="hidden items-stretch bg-white rid gap-4 mb-4 sm:grid-cols-1 sm:gap-6 sm:mb-5 p-4 h-full">


          <div class="w-full">
            <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
              {{ labels['TITLE_ATTRIBUTE_DESCRIPTION'][lg] }}
            </p>


            <div class="mt-6 w-full  text-left justify-left flex">
              <div class="w-full  ml-2 text-left justify-left">
                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {{ labels['TITLE_ATTRIBUTE_ID'][lg] }}</label>
                <input id="id" v-model="id" v-focus type="text" name="id" :disabled="(mode=='edit')"
                  class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  :class="{'bg-gray-50' : (mode=='edit'), 'bg-white':(mode=='new')}" placeholder="">
              </div>
            </div>


            <div class="mt-2 w-full  text-left justify-left flex">
              <div class="mt-4 w-full  text-left justify-left flex">
                <div class="w-full  ml-2 text-left justify-left">
                  <label for="name.FR" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    {{ labels['TITLE_ATTRIBUTE_NAME_FR'][lg] }}</label>
                  <input id="name.FR" v-model="nameFR" v-focus type="text" name="name.FR"
                    class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="">
                </div>
              </div>

              <div class="mt-4 w-full  text-left justify-left flex">
                <div class="w-full  ml-2 text-left justify-left">
                  <label for="name.EN" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    {{ labels['TITLE_ATTRIBUTE_NAME_EN'][lg] }}</label>
                  <input id="name.EN" v-model="nameEN" v-focus type="text" name="name.EN"
                    class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="">
                </div>
              </div>
            </div>



            <div class="mt-2 w-full flex text-left justify-left  flex-col ">
              <div class="w-full  text-left justify-left flex  ">
                <div class="w-full  ml-2 text-left justify-left">
                  <label for="description.FR" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    {{ labels['TITLE_ATTRIBUTE_DESCRIPTION_FR'][lg] }}</label>
                  <input id="description.FR" v-model="descriptionFR" v-focus type="text" name="description.FR"
                    class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="">
                </div>
              </div>

              <div class=" w-full  text-left justify-left flex  ">
                <div class="w-full  ml-2 text-left justify-left">
                  <label for="description.EN" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    {{ labels['TITLE_ATTRIBUTE_DESCRIPTION_EN'][lg] }}</label>
                  <input id="description.EN" v-model="descriptionEN" v-focus type="text" name="description.EN"
                    class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="">
                </div>
              </div>
            </div>


            <div class="mt-2 w-full  text-left justify-left">
              <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {{ labels['TITLE_ATTRIBUTE_TYPE'][lg] }}</label>



              <button id="dropdownSearchButtonAttributTypes" data-dropdown-toggle2="dropdownSearchAttributTypes"
                data-dropdown-placement="bottom"
                class="w-96 text-gray-900  border border-gray-300  bg-white hover:bg-gray-100 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button" @click="openSearch">
                <div class="w-full text-left">
                  {{ type?.name?.FR }}
                </div> <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="m1 1 4 4 4-4" />
                </svg>
              </button>

              <!-- Dropdown menu -->
              <div id="dropdownSearchAttributTypes"
                class="w-96 z-10 hidden bg-white rounded-lg shadow  dark:bg-gray-700">
                <div v-if="display == 'references'" class="h-72">
                  <div class="p-3 flex items-center cursor-pointer ">
                    <div class="  flex items-center mr-2 " @click="backAttributs">
                      <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="m14 8-4 4 4 4" />
                      </svg>

                      <div class="text-xs">
                        retour
                      </div>
                    </div>
                    <label for="input-group-search" class="sr-only">Search</label>
                    <div class="relative w-full">
                      <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                      </div>
                      <input id="input-group-search" type="text"
                        class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search  ">
                    </div>
                  </div>
                  <ul class="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownSearchButtonAttributTypes">
                    <li v-for="item in collections" :key="item.id" class="cursor-pointer"
                      @click="selectReference(item)">
                      <div class="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                        <label for="checkbox-item-11"
                          class="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">

                          {{ item.name.FR }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>


                <div v-if="display == 'attributs'" class="h-72">
                  <div class="p-3">
                    <label for="input-group-search" class="sr-only">Search</label>
                    <div class="relative">
                      <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                      </div>
                      <input id="input-group-search" type="text"
                        class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search user">
                    </div>
                  </div>
                  <ul class="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownSearchButtonAttributTypes">
                    <li v-for="item in types" :key="item.id" class="cursor-pointer" @click="selectType(item)">
                      <div class="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                        <label for="checkbox-item-11"
                          class="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">

                          {{ item.name.FR }}
                        </label>
                      </div>
                    </li>
                    <li>
                      <button id="doubleDropdownButton" type="button"
                        class=" flex items-center justify-between w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        @click="openReferences">
                        Dropdown<svg class="w-2.5 h-2.5 ms-3 rtl:rotate-180" aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 9 4-4-4-4" />
                        </svg>
                      </button>
                    </li>
                  </ul>
                  <a href="#"
                    class="flex items-center p-3 text-sm font-medium text-red-600 border-t border-gray-200 rounded-b-lg bg-gray-50 dark:border-gray-600 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-red-500 hover:underline">
                    <svg class="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                      viewBox="0 0 20 18">
                      <path
                        d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-6a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2Z" />
                    </svg>
                    Delete user
                  </a>
                </div>
              </div>
            </div>



            <div class="mt-6 w-full  text-left justify-left flex">
              <div class="w-full  ml-2 text-left justify-left">
                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {{ labels['TITLE_ATTRIBUTE_REFERENCE'][lg] }}</label>
                <input id="reference" v-model="reference" v-focus type="text" name="reference"
                  class="w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="">
              </div>
              <div class="w-full hidden  ml-2 text-left justify-left">
                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {{ labels['TITLE_ATTRIBUTE_PRICE'][lg] }}</label>
                <input id="price" v-model="price" v-focus type="number" name="price"
                  class="w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="">
              </div>
            </div>




            <div class="mt-6 w-full  text-left justify-left flex">
              <div class="w-full  ml-2 text-left justify-left">
                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {{ labels['TITLE_ATTRIBUTE_REFERENCE'][lg] }}</label>
                <input id="reference" v-model="reference" v-focus type="text" name="reference"
                  class="w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="">
              </div>
              <div class="w-full   ml-2 text-left justify-left">
                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {{ labels['TITLE_ATTRIBUTE_PRICE'][lg] }}</label>
                <input id="price" v-model="price" v-focus type="number" name="price"
                  class="w-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-600 focus:border-primary-600 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="">
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-end px-4  ">
          <button type="button"
            class="mr-3 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            @click=" onClose()">
            Annuler
          </button>
          <button type="button"
            class="inline-flex items-center text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
            @click="onValidate">
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import labels from "@/assets/lg/attribut.json"
  //   import service from "@/services/FullService.vue"
  import { mapGetters } from "vuex";
  import { Dropdown } from 'flowbite';

  export default {
    name: 'WildGameModal',
    props: {
      input: Object
    },
    computed: {
      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
    },
    data() {
      return ({
        lg: "FR",
        labels: labels,
        price: "",
        mode: "",
        display: "attributs",
        collections: [
          {
            id: "actors",
            name: {
              "FR": "Actors",
              "EN": "Actors"
            }
          },
          {
            id: "location",
            name: {
              "FR": "Locations",
              "EN": "Locations"
            }
          }],
        types: [
          {
            id: "string",
            name: {
              "FR": "Chaîne caracteres",
              "EN": "String"
            }
          },
          {
            id: "number",
            name: {
              "FR": "Nombre",
              "EN": "Nombre"
            }
          },
          {
            id: "reference",
            name: {
              "FR": "Référence",
              "EN": "Référence"
            }
          },
          {
            id: "object",
            name: {
              "FR": "Structure",
              "EN": "Structure"
            }
          },
          {
            id: "image",
            name: {
              "FR": "Image",
              "EN": "Image"
            }
          },
          {
            id: "identifier",
            name: {
              "FR": "identifier",
              "EN": "identifiant"
            }
          },
        ],


        id: null,

        type: {},
        quantity: 0,
        name: { FR: "", EN: "" },
        description: { FR: "", EN: "" },
        nameEN: "",
        nameFR: "",


        descriptionEN: "",
        descriptionFR: "",


        reference: "",
        dropdown: null,
        transparent: false,
        item: {
          id: "1",
          label: {
            'EN': "",
            'FR': ''
          }
        },
      })
    },
    watch: {
      'input': async function () {

        this.id = this.input?.id;
        this.mode = this.input?.mode;
        //                this.price = this.input?.price;
        //                this.name = this.input?.name

        if (this.input?.name != undefined) {
          this.nameFR = this.input?.name?.FR
          this.nameEN = this.input?.name?.EN
        }
        if (this.input?.description != undefined) {
          this.descriptionFR = this.input?.description?.FR
          this.descriptionEN = this.input?.description?.EN
        }
        //       this.url = this.input?.url,
        //       this.currency = this.input?.currency;
        //       this.reference = this.input?.reference;
        ///         let r = this.input?.type
        this.type = this.types.find(function (a) { return a.id == 'string' })

        // this.name = this.input
      }
    },
    async mounted() {
      this.id = this.input?.id

      if (this.input?.name != undefined) {
        this.nameFR = this.input?.name?.FR
        this.nameEN = this.input?.name?.EN
      }

      if (this.input?.description != undefined) {
        this.descriptionFR = this.input?.description?.FR
        this.descriptionEN = this.input?.description?.EN
      }


      this.mode = this.input?.mode;


      this.type = this.types.find(function (a) { a.id == 'string' })

      //  this.url = this.input?.url
      //  this.price = this.input?.price
      //  this.currency = this.input?.currency
      //  this.reference = this.input?.reference

      const options = {

        placement: 'bottom',
        triggerType: 'click',
        offsetSkidding: 0,
        offsetDistance: 10,
        delay: 300,
        ignoreClickOutsideClass: false,
        onHide: () => {
          console.log('dropdown has been hidden');
        },
        onShow: () => {
          console.log('dropdown has been shown');
        },
        onToggle: () => {
          console.log('dropdown has been toggled');
        },
      };

      const $targetEl = document.getElementById("dropdownSearchAttributTypes");
      const $triggerEl = document.getElementById("dropdownSearchButtonAttributTypes");

      if ($triggerEl != undefined) {

        this.dropdown = new Dropdown($targetEl, $triggerEl, options);
        // this.dropdown.hide()
      }

    },

    methods: {

      openPhoto(url) {
        window.open("https://files.b4food.io/b4food/images/" + url, '_blank'); 
      },
      
      backAttributs() {
        this.display = "attributs"
      },

      selectReference() {
        this.display = "attributs"


        this.type = {
          id: "reference",
          name: {
            "FR": "Lien vers",
            "EN": "Link to"
          }
        }

        this.dropdown.toggle()

      },

      openReferences() {
        this.display = "references"
      },

      openSearch() {

        // this.dropdown.show()
      },

      selectType(type) {
        this.type = type
        this.dropdown.toggle()
      },

      onValidate() {

        let item =
        {
          id: this.id,
          mode: this.mode,
          name: { EN: this.nameEN, FR: this.nameFR },
          description: { EN: this.descriptionEN, FR: this.descriptionFR },
          type: this.type?.id,
          /*   supplier: {
                 _id: this.supplier?._id,
                 name: this.supplier?.name,
                 image_id: this.supplier?.image_id
             }*/
        }
        this.$emit("confirm", item)
        // this.name = ""
      },
      onClose() {
        this.$emit("close")
        //  this.name = ""
      }
    },
    components: {

    },
  }
</script>